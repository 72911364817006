import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconButtonModule } from '@app/shared/component/icon-button/icon-button.module';
import { AutofocusModule } from '@app/shared/directive/autofocus.module';

import { SvgIconModule } from '../svg-icon/svg-icon.module';

import { InputSearchComponent } from './input-search.component';

@NgModule({
  declarations: [InputSearchComponent],
  imports: [CommonModule, SvgIconModule, IconButtonModule, AutofocusModule],
  exports: [InputSearchComponent],
})
export class InputSearchModule {}

import { MenuItem } from '@app/shared/component/menu-item/menu-item.model';
import {
  AbcRecommendationTypeStrict,
  AbcRecommendationTypeString,
  AbcReportEntryMetricKeyStrict,
  AbcReportMetric,
  AbcReportV3SortFieldStrict,
  StrictDictionary,
} from '@typings';

export const ABC_REPORT_SORT_FIELD: AbcReportV3SortFieldStrict = {
  PRODUCT_NAME: 'PRODUCT_NAME',
  PRODUCT_TYPE: 'PRODUCT_TYPE',
  PROFIT: 'PROFIT',
  PROFIT_GROUP: 'PROFIT_GROUP',
  PROFIT_PERCENT: 'PROFIT_PERCENT',
  REVENUE: 'REVENUE',
  REVENUE_GROUP: 'REVENUE_GROUP',
  REVENUE_PERCENT: 'REVENUE_PERCENT',
  SECTION_NAME: 'SECTION_NAME',
  UNIT_TYPE: 'UNIT_TYPE',
  ITEMS_COUNT: 'ITEMS_COUNT',
  ITEMS_COUNT_GROUP: 'ITEMS_COUNT_GROUP',
  ITEMS_COUNT_PERCENT: 'ITEMS_COUNT_PERCENT',
  ORDERS_COUNT: 'ORDERS_COUNT',
  ORDERS_COUNT_GROUP: 'ORDERS_COUNT_GROUP',
  ORDERS_COUNT_PERCENT: 'ORDERS_COUNT_PERCENT',
  FOOD_COST: 'FOOD_COST',
  FOOD_COST_GROUP: 'FOOD_COST_GROUP',
  FOOD_COST_OF_REVENUE_PERCENT: 'FOOD_COST_OF_REVENUE_PERCENT',
  FOOD_COST_PERCENT: 'FOOD_COST_PERCENT',
  RECOMMENDATION_TYPE: 'RECOMMENDATION_TYPE',
};

export const ABC_RECOM_TYPE: AbcRecommendationTypeStrict = {
  GO_LIST: 'GO_LIST',
  CONTROVERSIAL: 'CONTROVERSIAL',
  NOT_RECOMMENDED: 'NOT_RECOMMENDED',
  TO_REMOVE: 'TO_REMOVE',
};

export const ABC_RECOM_TYPE_STRING: AbcRecommendationTypeString = {
  CONTROVERSIAL: 'Принять решение необходимо самостоятельно',
  GO_LIST: 'Рекомендуем активно продвигать',
  NOT_RECOMMENDED: 'Не рекомендуем ставить в Go-list',
  TO_REMOVE: 'Рекомендуем убрать из меню',
};

export const ABC_WIDGET_METRICS: StrictDictionary<AbcReportMetric, AbcReportMetric> = {
  ordersCount: 'ordersCount',
  profit: 'profit',
  foodCost: 'foodCost',
  revenue: 'revenue',
};

export const ABC_WIDGET_METRICS_NAMES: StrictDictionary<string, AbcReportMetric> = {
  revenue: 'revenue',
  profit: 'profit',
  foodCost: 'foodCost',
  ordersCount: 'ordersCount',
};

export const ABC_REPORT_WIDGET_METRIC_NAME: AbcReportEntryMetricKeyStrict = {
  revenue: 'Выручка',
  foodCost: 'Себестоимость',
  ordersCount: 'Заказы',
  profit: 'Прибыль',
};

export const ABC_PERCENT_METRIC_NAME = {
  percentA: 'Группа А',
  percentB: 'Группа В',
  percentC: 'Группа С',
};

export const ABC_WIDGET_METRIC_COMPARE_NAMES = {
  empty: 'Выберите метрику',
  revenue: 'Выручка',
  foodCost: 'Себестоимость',
  ordersCount: 'Заказы',
  profit: 'Прибыль',
};

export const getAbcWidgetMetricsItems = (): MenuItem[] => {
  return Object.keys(ABC_WIDGET_METRICS_NAMES).map((metric) => ({
    id: metric,
    label: ABC_REPORT_WIDGET_METRIC_NAME[metric],
    type: 'item',
  }));
};

export const getAbcCompareWidgetNames = (currentMetric: AbcReportMetric): MenuItem[] => {
  return Object.keys(ABC_WIDGET_METRIC_COMPARE_NAMES)
    .filter((metric) => metric !== currentMetric)
    .map((metric) => ({
      id: metric,
      label: ABC_WIDGET_METRIC_COMPARE_NAMES[metric],
      type: 'item',
    }));
};

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from '@app/shared/component/button/button.module';
import { ControlHintModule } from '@app/shared/component/control-hint/control-hint.module';
import { FilterAbcComponent } from '@app/shared/component/filter-abc/filter-abc.component';
import { FilterHeaderModule } from '@app/shared/component/filter-header/filter-header.module';
import { FilterLabelModule } from '@app/shared/component/filter-label/filter-label.module';
import { FilterPanelModule } from '@app/shared/component/filter-panel/filter-panel.module';
import { InputModule } from '@app/shared/component/input/input.module';
import { LetModule } from '@ngrx/component';

@NgModule({
  declarations: [FilterAbcComponent],
  imports: [
    CommonModule,
    FilterPanelModule,
    FilterLabelModule,
    FilterHeaderModule,
    ButtonModule,
    InputModule,
    FormsModule,
    ReactiveFormsModule,
    ControlHintModule,
    LetModule,
  ],
  exports: [FilterAbcComponent],
})
export class FilterAbcModule {}

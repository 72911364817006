import { Component, HostListener, OnInit, Renderer2, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Observable } from 'rxjs';
import { distinctUntilChanged, take } from 'rxjs/operators';

import { BreakpointObserverService } from '@app/core/service/breakpoint-observer.service';
import { ColorThemeService } from '@app/shared/service/color-theme/color-theme.service';
import { DetectDeviceService } from '@app/shared/service/detect-device/detect-device.service';
import { VersionService } from '@app/shared/service/version/version.service';
import { ConnectCheckService } from '@core/service/connect-check.service';
import { PopupService } from '@core/service/popup.service';
import { SessionStorage } from '@services/api';
import { AuthService } from '@services/auth';
import { AccountStorage } from '@services/core';
import { Popup } from '@typings';

import { ConfigService } from './config.service';

@Component({
  selector: 'nm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('slideBarContainer', { read: ViewContainerRef })
  slideBarContainer: TemplateRef<unknown>;

  public popup$: Observable<Popup>;

  public isMinWidth1024 = false;
  public isSliderBar = false;
  public slideBarClassName = '';

  public isOrganization = false;

  @HostListener('window:resize') onClickOutside(): void {
    document.documentElement.style.setProperty('--vh', window.innerHeight * 0.01 + 'px');
  }

  constructor(
    private versionService: VersionService,
    private connectCheckService: ConnectCheckService,
    private breakpointObserverService: BreakpointObserverService,
    private popupService: PopupService,
    private detectDeviceService: DetectDeviceService,
    private sessionStorage: SessionStorage,
    private gtmService: GoogleTagManagerService,
    private router: Router,
    private configService: ConfigService,
    private accountStorage: AccountStorage,
    private renderer: Renderer2,
    private authService: AuthService,
    private colorThemeService: ColorThemeService,
  ) {
    this.detectDeviceService.deviceDetection().pipe(take(1)).subscribe();
    this.popup$ = this.popupService.popup$;
    this.colorThemeService.load();
  }

  ngOnInit(): void {
    this.authService.auth();

    if (this.sessionStorage.getOrgId()) {
      this.isOrganization = true;
    }

    document.documentElement.style.setProperty('--vh', window.innerHeight * 0.01 + 'px');

    this.breakpointObserverService.observe();
    this.isMinWidth1024 = this.breakpointObserverService.minWidth1024;

    if (!this.configService.config.local) {
      this.router.events.forEach((item) => {
        if (item instanceof NavigationEnd) {
          const gtmTag = {
            event: 'page',
            pageName: item.url,
          };
          this.gtmService.pushTag(gtmTag);
        }
      });
    }
    this.accountStorage.accountFetched.pipe(distinctUntilChanged(), take(1)).subscribe(() => {
      let loader = this.renderer.selectRootElement('#loader');
      this.renderer.setStyle(loader, 'display', 'none');
    });
  }
}

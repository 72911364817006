import { gql } from 'apollo-angular';

import { fragmentPage } from './mutations';

export const queryChartReportsExistsForOrganization = gql`
  query chartReportsExistsForOrganization {
    chartReportsExistsForOrganization
  }
`;

export const queryAbcReportsExist = gql`
  query abcReportsExist($storeIds: [UUID!]) {
    abcReportsExist(storeIds: $storeIds)
  }
`;

export const queryAbcReportV2 = gql`
  query abcReportV2($pageRequest: PageRequestInput, $input: AbcReportInput!, $sort: [AbcReportV2Sort!]) {
    abcReportV2(input: $input) {
      rows(pageRequest: $pageRequest, sort: $sort) {
        content {
          categories {
            id
            name
          }
          name
          productType
          profit
          profitPercent
          profitPercentOutOfTotal
          quantity
          revenue
          revenuePercentOutOfTotal
          unitType
        }
        ...Page
      }
      total {
        percentageProfit
        percentageRevenue
        profit
        quantity
        revenue
      }
    }
  }
  ${fragmentPage}
`;

export const queryAbcReportWidget = gql`
  query abcReportV3Widget(
    $input: AbcReportV3Input!
    $grouping: DateGrouping!
    $revenue: Boolean!
    $profit: Boolean!
    $foodCost: Boolean!
    $ordersCount: Boolean!
  ) {
    abcReportV3Widget(input: $input, grouping: $grouping) {
      revenue @include(if: $revenue) {
        a
        b
        c
        timestamp
      }
      profit @include(if: $profit) {
        a
        b
        c
        timestamp
      }
      ordersCount @include(if: $ordersCount) {
        a
        b
        c
        timestamp
      }
      foodCost @include(if: $foodCost) {
        a
        b
        c
        timestamp
      }
    }
  }
`;

export const queryAbcReportV3 = gql`
  query abcReportV3($pageRequest: PageableInput!, $input: AbcReportV3Input!, $sort: [AbcReportV3Sort!]) {
    abcReportV3(input: $input) {
      rows(page: $pageRequest, sort: $sort) {
        content {
          itemsCount
          itemsCountGroup
          itemsCountPercent
          itemsCountRoll
          nestedSections {
            id
            name
          }
          ordersCount
          ordersCountGroup
          ordersCountPercent
          ordersCountRoll
          productId
          productName
          productType
          profit
          profitGroup
          profitPercent
          profitRoll
          revenue
          revenueGroup
          revenuePercent
          revenueRoll
          sectionId
          sectionName
          unitType
          foodCost
          foodCostOfRevenuePercent
          foodCostGroup
          recommendationType
        }
        ...Page
      }
      total {
        itemsCountTotal
        ordersCountTotal
        profitTotal
        revenueTotal
        foodCostTotal
        foodCostOfRevenueTotal
      }
    }
  }
  ${fragmentPage}
`;

export enum AnalyticsRoute {
  dashboardReport = 'dashboard-report',
  dashboardClientReport = 'dashboard-client-report',
  dashboardEmployees = 'dashboard-employees',
  dashboardTables = 'dashboard-tables',
  abcReport = 'abc-report',
  categoriesReport = 'categories-report',
  surchargeReport = 'surcharge-report',
  consolidatedReport = 'consolidated-report',
  employeesReport = 'employees-report',
  paymentSystemsReport = 'payment-systems-report',
  tablesReport = 'tables-report',
  productsReport = 'products-report',
  storesReport = 'stores-report',
  workshopsReport = 'workshops-report',
}

export enum AnalyticsRouteName {
  dashboardReport = 'Обзор организации',
  dashboardClientReport = 'Обзор клиентов',
  dashboardEmployees = 'Обзор по сотрудникам',
  dashboardTables = 'Обзор по столам',
  abcReport = 'ABC-отчет',
  categoriesReport = 'Отчет по категориям',
  surchargeReport = 'Отчет по наценкам',
  consolidatedReport = 'Отчет по продажам',
  employeesReport = 'Отчет по сотрудникам',
  paymentSystemsReport = 'Отчет по способам оплаты',
  tablesReport = 'Отчет по столам',
  productsReport = 'Отчет по товарам',
  storesReport = 'Отчет по точкам продаж',
  workshopsReport = 'Отчет по цехам',
}

export enum AnalyticsRouteParam {
  productId = 'idProduct',
}

export const ANALYTICS_ITEM_ABC = {
  title: AnalyticsRouteName.abcReport,
  route: AnalyticsRoute.abcReport,
};

export const ANALYTICS_ITEM_EMPLOYEES_REPORT = {
  title: AnalyticsRouteName.employeesReport,
  route: AnalyticsRoute.employeesReport,
};

export const ANALYTICS_ITEM_PRODUCTS = {
  title: AnalyticsRouteName.productsReport,
  route: AnalyticsRoute.productsReport,
  routes: [
    {
      title: '',
      route: AnalyticsRouteParam.productId,
      keepUrlParams: true,
    },
  ],
};

export const ANALYTICS_ITEM_CATEGORIES = {
  title: AnalyticsRouteName.categoriesReport,
  route: AnalyticsRoute.categoriesReport,
};

export const ANALYTICS_ITEM_DASHBOARD = {
  title: AnalyticsRouteName.dashboardReport,
  route: AnalyticsRoute.dashboardReport,
};

export const ANALYTICS_ITEM_DASHBOARD_CLIENT = {
  title: AnalyticsRouteName.dashboardClientReport,
  route: AnalyticsRoute.dashboardClientReport,
};

export const ANALYTICS_ITEM_SURCHARGE = {
  title: AnalyticsRouteName.surchargeReport,
  route: AnalyticsRoute.surchargeReport,
};

export const ANALYTICS_ITEM_DASHBOARD_EMPLOYEES = {
  title: AnalyticsRouteName.dashboardEmployees,
  route: AnalyticsRoute.dashboardEmployees,
};

export const ANALYTICS_ITEM_PAYMENT_SYSTEMS = {
  title: AnalyticsRouteName.paymentSystemsReport,
  route: AnalyticsRoute.paymentSystemsReport,
};

export const ANALYTICS_ITEM_CONSOLIDATED = {
  title: AnalyticsRouteName.consolidatedReport,
  route: AnalyticsRoute.consolidatedReport,
};

export const ANALYTICS_ITEM_DASHBOARD_TABLES = {
  title: AnalyticsRouteName.dashboardTables,
  route: AnalyticsRoute.dashboardTables,
};

export const ANALYTICS_ITEM_TABLES = {
  title: AnalyticsRouteName.tablesReport,
  route: AnalyticsRoute.tablesReport,
};

export const ANALYTICS_ITEM_WORKSHOPS_REPORT = {
  title: AnalyticsRouteName.workshopsReport,
  route: AnalyticsRoute.workshopsReport,
};

export const ANALYTICS_ITEM_STORES_REPORT = {
  title: AnalyticsRouteName.storesReport,
  route: AnalyticsRoute.storesReport,
};

export enum ANALYTICS_SUB_TITLE_ITEMS {
  sale = 'Продажи',
  marketing = 'Маркетинг',
  employee = 'Персонал',
  tables = 'Столы',
  finance = 'Финансы',
}

export const ANALYTICS_SALE_CHILD_ITEMS = [
  ANALYTICS_ITEM_ABC,
  ANALYTICS_ITEM_CATEGORIES,
  ANALYTICS_ITEM_SURCHARGE,
  ANALYTICS_ITEM_CONSOLIDATED,
  ANALYTICS_ITEM_PRODUCTS,
  ANALYTICS_ITEM_STORES_REPORT,
  ANALYTICS_ITEM_WORKSHOPS_REPORT,
];

export const ANALYTICS_MARKETING_CHILD_ITEMS = [ANALYTICS_ITEM_DASHBOARD_CLIENT];

export const ANALYTICS_EMPLOYEE_CHILD_ITEMS = [ANALYTICS_ITEM_DASHBOARD_EMPLOYEES, ANALYTICS_ITEM_EMPLOYEES_REPORT];

export const ANALYTICS_TABLES_CHILD_ITEMS = [ANALYTICS_ITEM_DASHBOARD_TABLES, ANALYTICS_ITEM_TABLES];

export const ANALYTICS_FINANCE_CHILD_ITEMS = [ANALYTICS_ITEM_PAYMENT_SYSTEMS];

export const ANALYTICS_CHILD_ITEMS = [
  ANALYTICS_ITEM_DASHBOARD,
  {
    title: ANALYTICS_SUB_TITLE_ITEMS.sale,
    routes: ANALYTICS_SALE_CHILD_ITEMS,
    isSubRouteClose: true,
    isSubTitle: true,
  },
  {
    title: ANALYTICS_SUB_TITLE_ITEMS.marketing,
    routes: ANALYTICS_MARKETING_CHILD_ITEMS,
    isSubRouteClose: true,
    isSubTitle: true,
  },
  {
    title: ANALYTICS_SUB_TITLE_ITEMS.employee,
    routes: ANALYTICS_EMPLOYEE_CHILD_ITEMS,
    isSubRouteClose: true,
    isSubTitle: true,
  },
  {
    title: ANALYTICS_SUB_TITLE_ITEMS.tables,
    routes: ANALYTICS_TABLES_CHILD_ITEMS,
    isSubRouteClose: true,
    isSubTitle: true,
  },
  {
    title: ANALYTICS_SUB_TITLE_ITEMS.finance,
    routes: ANALYTICS_FINANCE_CHILD_ITEMS,
    isSubRouteClose: true,
    isSubTitle: true,
  },
];

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import { SearchService } from '@app/shared/service/search/search.service';

@Component({
  selector: 'nm-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputSearchComponent implements OnDestroy, AfterViewInit, OnInit {
  @Input() placeholder = 'Поиск';
  @Input() testId: string;
  @Input() initialValue: string;
  @Input() lettersThreshold: number = 0;
  @Input() isTopBarSearch = false;

  private destroy$ = new Subject<void>();

  iconsColor = '#5B5E66';

  @Output() searchKeyup = new EventEmitter<string>();

  @ViewChild('input') input: ElementRef;

  keyup$ = new Subject<string>();
  value$: Observable<string> = this.keyup$.asObservable();

  constructor(private searchService: SearchService) {}

  ngOnInit(): void {
    const keyupWithThreshold$ = this.lettersThreshold
      ? this.keyup$.pipe(map((val) => (val.length < this.lettersThreshold ? '' : val)))
      : this.keyup$;

    keyupWithThreshold$.pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.destroy$)).subscribe((val) => {
      this.searchKeyup.emit(val);
    });
  }

  setValue(value: string) {
    if (this.input && this.keyup$) {
      this.input.nativeElement.focus();
      this.input.nativeElement.value = value;
      this.keyup$.next(value);
    }
  }

  ngAfterViewInit(): void {
    if (this.initialValue) {
      this.setValue(this.initialValue);
    }
  }

  onKeyUp(value: string) {
    this.keyup$.next(String(value));
  }

  removeValue() {
    this.setValue('');
  }

  setSearch() {
    this.searchService.openSearch$.next(false);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { combineLatest, Observable, shareReplay } from 'rxjs';
import { filter, map, pairwise, startWith, switchMap, tap } from 'rxjs/operators';

import { MenuSectionTreeService } from '@app/modules/catalog/services/menu-categories-tree.service';
import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { MenuItemType } from '@app/shared/component/menu-item/menu-item.model';
import { TreeDataService } from '@app/shared/service/tree-data/tree-data.service';
import { MAX_CHARACTERS } from '@constants';
import { ValidationErrorsService } from '@core/service';
import { MenuService, MenuStorage } from '@services/catalog';
import { TreeDataAutocompleteService } from '@services/shared';
import { CoreSchema, EditItemForm, ItemRc, ModifierGroupOutput, Product, StockUnit } from '@typings';
import { getHexCodeByColor } from '@utils';

@Component({
  selector: 'nm-edit-item-dialog',
  templateUrl: './edit-item-dialog.component.html',
  styleUrls: ['./edit-item-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditItemDialogComponent {
  MAX_CHARACTERS = MAX_CHARACTERS;
  sections$: Observable<AutocompleteOption<CoreSchema.SectionRc>[]>;
  productOptions$: Observable<AutocompleteOption<Product>[]>;
  modifierGroups$: Observable<ModifierGroupOutput[]>;
  subsectionId: string | null | undefined = null;
  treeDataService: TreeDataService<AutocompleteOption>;
  loading = false;

  autocompleteTreeService = new TreeDataAutocompleteService();

  get btnTitle(): string {
    return 'Сохранить';
  }

  get title() {
    return 'Редактирование позиции';
  }

  get form(): FormGroup<EditItemForm> {
    return this.menuService.editItemForm;
  }

  get controls() {
    return this.form.controls;
  }

  get product() {
    return this.controls.product;
  }

  get subsection() {
    return this.controls.subsection;
  }

  get name() {
    return this.controls.name;
  }
  get isProductError() {
    return this.product.dirty && this.product.invalid;
  }

  get isSubsectionError() {
    return this.subsection.dirty && this.subsection.invalid;
  }

  get menuItem(): ItemRc {
    return this.data.menuItem;
  }

  get itemImage(): string | undefined {
    const images = this.data.stockUnit ? this.data?.stockUnit?.images : undefined;
    return (images && images[0]?.imageSizes[0].url) || undefined;
  }

  get stockUnit(): StockUnit {
    return this.data.stockUnit;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      menuItem: ItemRc;
      menuId: string;
      stockUnit: StockUnit;
    },
    public menuService: MenuService,
    public validationErrorsService: ValidationErrorsService,
    private menuStorage: MenuStorage,
    private menuSectionTreeService: MenuSectionTreeService,
  ) {
    this.menuService.initEditItemForm(this.menuItem, this.stockUnit);

    this.initSubsections();

    this.initProductOptions();

    this.initModifierGroups();
    this.treeDataService = this.menuSectionTreeService.initSections();
    this.autocompleteTreeService.init(this.treeDataService);
  }

  private initModifierGroups(): void {
    this.modifierGroups$ = this.controls.product.valueChanges.pipe(
      startWith(this.controls.product.value),
      pairwise(),
      filter(([prev, product]) => product?.id !== prev?.id),
      map((res) => res[1]),
      switchMap((product) => this.menuService.getModifierGroups(product?.data)),
    );
  }

  private getProductName(product: Product): string {
    let name = product.name;
    if (product.section) {
      name += ` (${product.section.name})`;
    }
    return name;
  }

  private initProductOptions(): void {
    this.productOptions$ = this.controls.subsection.valueChanges.pipe(
      startWith(this.data.menuItem.sectionId || undefined),
      filter((subsectionId) => this.subsectionId !== subsectionId),
      tap((value) => (this.subsectionId = value)),
      switchMap((subsectionId: string | undefined) =>
        this.loadProducts(subsectionId).pipe(
          map((products) => {
            return products.map((p) => ({
              id: p.id,
              label: this.getProductName(p),
              type: 'item' as MenuItemType,
              data: p,
            }));
          }),
        ),
      ),
      shareReplay(),
    );
  }

  private loadProducts(subsectionId: string | undefined): Observable<Product[]> {
    return combineLatest([this.menuService.getAllProducts(), this.menuService.getMenuItemsBySection(subsectionId)]).pipe(
      map(([products, existingMenuItems]) => {
        return products.filter((product) => {
          if (!product.stockUnits) return false;
          const productStockUnitId = product.stockUnits[0].id;
          if (productStockUnitId === this.stockUnit?.id) return true;
          return !existingMenuItems.map((item) => item.stockUnit?.id).includes(productStockUnitId);
        });
      }),
    );
  }

  private initSubsections(): void {
    this.sections$ = this.menuStorage
      .getAllInMenuPageable({ filter: { menuIds: [this.data.menuId], types: ['SECTION'] }, pageable: { page: 0, size: 1000 } })
      .pipe(
        map((res) => {
          const sections = res.data.menuRcElements.elements as CoreSchema.SectionRc[];
          return sections.map((section) => {
            return {
              id: section.id,
              label: section.name,
              type: 'item' as MenuItemType,
              imageColor: section.color ? getHexCodeByColor(section.color) : undefined,
              data: section,
            };
          });
        }),
      );
  }

  closeForm(confirm: boolean) {
    this.menuService.closeEditItemDialog(confirm);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';

import { FilterPanelModule } from '../filter-panel/filter-panel.module';
import { FormFieldModule } from '../form-field/form-field.module';
import { IconButtonModule } from '../icon-button/icon-button.module';
import { MenuModule } from '../menu/menu.module';

import { FilterHeaderComponent } from './filter-header.component';

@NgModule({
  declarations: [FilterHeaderComponent],
  imports: [CommonModule, FormFieldModule, MenuModule, MatMenuModule, FilterPanelModule, IconButtonModule],
  exports: [FilterHeaderComponent],
})
export class FilterHeaderModule {}

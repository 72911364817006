import { gql } from 'apollo-angular';

export const mutationOnlineMenuSetActiveState = gql`
  mutation onlineMenuSetActiveState($input: OnlineMenuSetActiveStateInput!) {
    onlineMenuSetActiveState(input: $input) {
      onlineMenu {
        id
        active
        shortId
      }
    }
  }
`;

export const mutationOnlineMenuSectionSetImage = gql`
  mutation onlineMenuSectionSetImage($input: OnlineMenuSectionSetImageInput!) {
    onlineMenuSectionSetImage(input: $input) {
      imageId
    }
  }
`;

export const queryMenuSectionsBySection = gql`
  query menuSectionsBySection($sectionId: UUID!) {
    menuSectionsBySection(sectionId: $sectionId) {
      id
      name
      depthLevel
      parentSectionId
      active
      orderPosition
      isParent
      countOfItems {
        inactive
        total
      }
      colorSection
    }
  }
`;

export const queryMenuSection = gql`
  query menuSection($id: UUID!) {
    menuSection(id: $id) {
      id
      name
      depthLevel
      colorSection
      image {
        id
        imageSizes {
          url
        }
      }
      parentSectionId
      active
      orderPosition
      countOfItems {
        inactive
        total
      }
      countOfSections {
        inactive
        total
      }
      onlineMenuSection {
        menuSectionId
        imageId
        imageUrl
      }
    }
  }
`;

export const queryAllMenuItemsBySection = gql`
  query allMenuItemsBySection($sectionId: UUID!) {
    allMenuItemsBySection(sectionId: $sectionId) {
      id
      name
      active
      menuSectionId
      orderPosition
      itemSalePrice {
        amountValue
        currencyUnit
      }
      stockUnit {
        id
        name
        unit
        primePrice {
          amountValue
          currencyUnit
        }
        images {
          id
          imageSizes {
            url
          }
        }
      }
      onlineMenuItem {
        menuItemId
        imageId
        imageUrl
      }
    }
  }
`;

export const queryAllMenus = gql`
  query allMenus($storeId: UUID!) {
    allMenus(storeId: $storeId) {
      id
      name
      storeId
      type
      countOfItems {
        inactive
        total
      }
      onlineMenu {
        id
        active
        shortId
      }
    }
  }
`;

export const queryAllMenusByStoreIdsPageable = gql`
  query menusRc($storeIds: [UUID!]!, $filter: MenuRcFilterInput, $pageRequest: PageRequestInput!) {
    menusRc(storeIds: $storeIds, filter: $filter, pageRequest: $pageRequest) {
      page {
        totalPages
        total
        pageNumber
      }
      menus {
        id
        catalogId
        itemsCount {
          total
          inactive
        }
        name
        storeIds
        type
      }
    }
  }
`;
// export const queryAllMenusByStoreIdsPageable = gql`
//   query allMenusByStoreIdsPageable($storeIds: [UUID!]!, $filter: MenuFilterInput, $pageRequest: PageRequestInput) {
//     allMenusByStoreIdsPageable(storeIds: $storeIds, filter: $filter, pageRequest: $pageRequest) {
//       totalPages
//       total
//       content {
//         id
//         name
//         storeId
//         type
//         countOfItems {
//           inactive
//           total
//         }
//         onlineMenu {
//           id
//           active
//           shortId
//         }
//       }
//     }
//   }
// `;

export const queryMenuItem = gql`
  query menuItem($id: UUID!) {
    menuItem(id: $id) {
      id
      name
      active
      menuSectionId
      orderPosition
      itemSalePrice {
        amountValue
        currencyUnit
      }
      stockUnit {
        id
        name
        unit
        primePrice {
          amountValue
          currencyUnit
        }
        images {
          id
          imageSizes {
            url
          }
        }
      }
      onlineMenuItem {
        menuItemId
        imageId
        imageUrl
      }
    }
  }
`;

import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { TimeRangeInput } from '@app/shared/component/filter-for-days/filter-for-days.component';
import { MenuItem } from '@app/shared/component/menu-item/menu-item.model';
import { DAY_OF_WEEK } from '@constants/day-of-week';
import { DayOfWeek } from '@typings';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_VIEW = 'DD.MM.YYYY';
export const DATE_FULL_FORMAT_VIEW = 'DD MMM YYYY';
export const DATE_FULL_WITH_TIME_FORMAT_VIEW = 'DD.MM.YYYY, HH:mm';
export const DATE_TIME_FULL_FORMAT_VIEW = 'DD MMM YYYY HH:mm';
export const DATE_FULL_TEMPLATE_WITH_SECOND = 'DD.MM.YYYY HH:mm:ss';
export const DATE_FULL_TEMPLATE_V2 = 'DD.MM.YYYY HH:mm';
export const MONTH_DATE_FORMAT = 'D MMM';
export const MONTH_DAY_FORMAT = 'DD MMM';
export const MONTH_DAY_FORMAT_V2 = 'DD.MM';
export const MONTH_YEAR_FORMAT = 'MMM YY';
export const FULL_MONTH_YEAR_FORMAT = 'MMMM YYYY';
export const TIME_FORMAT = 'HH:mm:ss';
export const HOUR_MINUTE_FORMAT = 'HH:mm';
export const DATE_TEMPLATE = 'dd.MM.yyyy';
export const DATE_FULL_TEMPLATE = 'dd.MM.yyyy HH:mm';
export const DAY_TEMPLATE = 'EEE';
export const DATE_ISO = 'YYYY-MM-DDT00:00:00.000[Z]';
export const DATE_PRESETS: DATE_PRESETS_TYPE = {
  CUSTOM: 'Произвольный период',
  TODAY: 'Сегодня',
  CURRENT_WEEK: 'Текущая неделя',
  CURRENT_MONTH: 'Текущий месяц',
  YESTERDAY: 'Вчера',
  LAST_WEEK: 'Прошлая неделя',
  LAST_MONTH: 'Прошлый месяц',
  LAST_SEVEN_DAYS: 'Последние 7 дней',
  LAST_THIRTY_DAYS: 'Последние 30 дней',
  LAST_ONE_YEAR: 'Последние 12 месяцев',
};

export type DATE_PRESETS_TYPE = {
  CUSTOM: string;
  TODAY: string;
  CURRENT_WEEK: string;
  CURRENT_MONTH: string;
  YESTERDAY: string;
  LAST_WEEK: string;
  LAST_MONTH: string;
  LAST_SEVEN_DAYS: string;
  LAST_THIRTY_DAYS: string;
  LAST_ONE_YEAR: string;
};

export const DATE_PRESETS_LIST: AutocompleteOption<keyof DATE_PRESETS_TYPE>[] = Object.keys(DATE_PRESETS).map((key) => {
  return {
    id: key,
    label: DATE_PRESETS[key],
    type: 'item',
    data: key,
  };
});

export const DATE_FILTERS: DATE_FILTERS_TYPE = {
  TODAY: 'Сегодня',
  CURRENT_WEEK: 'Текущая неделя',
  CURRENT_MONTH: 'Текущий месяц',
  CURRENT_YEAR: 'Текущий год',
  YESTERDAY: 'Вчера',
  LAST_WEEK: 'Прошлая неделя',
  LAST_MONTH: 'Прошлый месяц',
  LAST_YEAR: 'Прошлый год',
  CUSTOM: 'Произвольный период',
};

export const DATE_FILTERS_COMPARE: DATE_FILTERS_TYPE = {
  TODAY: 'со вчера',
  CURRENT_WEEK: 'с этими днями на прошлой неделе',
  CURRENT_MONTH: 'с этими днями в прошлом месяце',
  CURRENT_YEAR: 'c предыдушим годом',
  YESTERDAY: 'с позавчера',
  LAST_WEEK: 'с позапрошлой неделей',
  LAST_MONTH: 'с позапрошлым месяцем',
  LAST_YEAR: 'c позапрошлым годом',
  CUSTOM: '',
};

export type DATE_FILTERS_TYPE = {
  CUSTOM: string;
  TODAY: string;
  CURRENT_WEEK: string;
  CURRENT_MONTH: string;
  CURRENT_YEAR: string;
  YESTERDAY: string;
  LAST_WEEK: string;
  LAST_MONTH: string;
  LAST_YEAR: string;
};

export const DATE_FILTER_LIST: MenuItem[] = Object.keys(DATE_FILTERS).map((key) => {
  return {
    id: key,
    label: DATE_FILTERS[key],
    type: 'item',
    description: DATE_FILTERS_COMPARE[key],
    showItemDescription: key === 'CUSTOM',
  };
});

export type DAY_PRESETS_TYPE = {
  ALL: string;
  WEEKDAY: string;
  WEEKENDS: string;
  WEEKEND: string;
  MINI_WEEKEND: string;
};
export type DAY_PRESETS_TYPE_KEY = keyof DAY_PRESETS_TYPE;

export type DAY_PRESETS_TYPE_VALUE = {
  ALL: DayOfWeek[];
  WEEKDAY: DayOfWeek[];
  WEEKENDS: DayOfWeek[];
  WEEKEND: DayOfWeek[];
  MINI_WEEKEND: DayOfWeek[];
};

export const DAY_FILTERS: DAY_PRESETS_TYPE = {
  ALL: 'Все дни',
  WEEKDAY: 'Будние дни',
  WEEKENDS: 'Выходные',
  WEEKEND: 'Уикенд',
  MINI_WEEKEND: 'Будние вечера',
};
export const DAY_FILTERS_VALUE: DAY_PRESETS_TYPE_VALUE = {
  ALL: [
    DAY_OF_WEEK.MONDAY,
    DAY_OF_WEEK.TUESDAY,
    DAY_OF_WEEK.WEDNESDAY,
    DAY_OF_WEEK.THURSDAY,
    DAY_OF_WEEK.FRIDAY,
    DAY_OF_WEEK.SATURDAY,
    DAY_OF_WEEK.SUNDAY,
  ],
  WEEKDAY: [DAY_OF_WEEK.MONDAY, DAY_OF_WEEK.TUESDAY, DAY_OF_WEEK.WEDNESDAY, DAY_OF_WEEK.THURSDAY, DAY_OF_WEEK.FRIDAY],
  WEEKENDS: [DAY_OF_WEEK.SATURDAY, DAY_OF_WEEK.SUNDAY],
  WEEKEND: [DAY_OF_WEEK.FRIDAY, DAY_OF_WEEK.SATURDAY, DAY_OF_WEEK.SUNDAY],
  MINI_WEEKEND: [DAY_OF_WEEK.MONDAY, DAY_OF_WEEK.SUNDAY],
};

export const DAY_FILTERS_DESC: DAY_PRESETS_TYPE = {
  ALL: 'Пн — Вс',
  WEEKDAY: 'Пн — Пт',
  WEEKENDS: 'Сб — Вс',
  WEEKEND: 'Пт — Вс',
  MINI_WEEKEND: 'Вс — Пн',
};

export const DAY_FILTER_LIST: MenuItem[] = Object.keys(DAY_FILTERS).map((key) => {
  return {
    id: key,
    label: DAY_FILTERS[key],
    type: 'item',
    description: DAY_FILTERS_DESC[key],
  };
});

export type HOUR_PRESETS_TYPE = {
  ALL: string;
  MORNING: string;
  LUNCH: string;
  EVENING: string;
  NIGHT: string;
};

export type HOUR_PRESETS_TYPE_VALUE = {
  MORNING: TimeRangeInput;
  LUNCH: TimeRangeInput;
  EVENING: TimeRangeInput;
  NIGHT: TimeRangeInput;
};

export const HOUR_FILTERS_ID: HOUR_PRESETS_TYPE = {
  ALL: 'ALL',
  MORNING: 'MORNING',
  LUNCH: 'LUNCH',
  EVENING: 'EVENING',
  NIGHT: 'NIGHT',
};

export const HOUR_FILTERS: HOUR_PRESETS_TYPE = {
  ALL: 'Все часы',
  MORNING: 'Утро',
  LUNCH: 'Обед',
  EVENING: 'Вечер',
  NIGHT: 'Ночь',
};

export const HOUR_FILTERS_DESC: HOUR_PRESETS_TYPE = {
  ALL: '',
  MORNING: 'с 06:00 до 12:00',
  LUNCH: 'с 12:00 до 16:00',
  EVENING: 'с 16:00 до 23:00',
  NIGHT: 'с 23:00 до 6:00',
};

export const HOUR_FILTERS_VALUE: HOUR_PRESETS_TYPE_VALUE = {
  MORNING: { timeFrom: '06:00', timeTo: '12:00' },
  LUNCH: { timeFrom: '12:00', timeTo: '16:00' },
  EVENING: { timeFrom: '16:00', timeTo: '23:00' },
  NIGHT: { timeFrom: '23:00', timeTo: '06:00' },
};

export const HOUR_FILTER_LIST: MenuItem[] = Object.keys(HOUR_FILTERS).map((key) => {
  return {
    id: key,
    label: HOUR_FILTERS[key],
    type: 'item',
    description: HOUR_FILTERS_DESC[key],
  };
});

import { Injectable } from '@angular/core';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  private version: string = environment.appVersion;

  constructor() {
    this.updateAssets();
  }

  private updateAssets(): void {
    if (!this.version) return;
    document.querySelectorAll<HTMLScriptElement>('script[src]').forEach((script) => {
      script.src = this.appendVersion(script.src);
    });

    document.querySelectorAll<HTMLLinkElement>('link[rel="stylesheet"]').forEach((link) => {
      link.href = this.appendVersion(link.href);
    });
  }

  private appendVersion(url: string): string {
    const updatedUrl = new URL(url, window.location.origin);
    updatedUrl.searchParams.set('v', this.version);
    return updatedUrl.toString();
  }
}

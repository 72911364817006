import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { MenuApi } from '@services/catalog';
import { NotifyService } from '@services/shared';
import {
  MenuRc,
  MutationAddMenuRcArgs,
  MutationCreateMenuRcArgs,
  MutationCreateSectionRcArgs,
  MutationDeleteItemRcArgs,
  MutationDeleteMenuRcArgs,
  MutationDeleteSectionRcArgs,
  MutationDuplicateMenuRcArgs,
  MutationMoveElementsArgs,
  MutationResult,
  MutationUpdateItemRcArgs,
  MutationUpdateMenuRcArgs,
  MutationUpdateSectionRcArgs,
  QueryAllCatalogProductsPageableArgs,
  QueryAllMenuItemsBySectionArgs,
  QueryAllMenusByStoreIdsPageableArgs,
  QueryAllRcMenusByStoreIdsPageableArgs,
  QueryMenuRcArgs,
  QueryMenuRcElementArgs,
  QueryMenuRcElementsArgs,
  QueryMenuRcImmediateDescendantsArgs,
  QueryMenuRcRootElementsArgs,
  QueryResult,
  QueryStockUnitsArgs,
  Store,
} from '@typings';

import { ProductApi } from '../product/product.api';

@Injectable({
  providedIn: 'root',
})
export class MenuStorage {
  #stores = new BehaviorSubject<Store[]>([]);
  stores$ = this.#stores.asObservable();

  #menu = new BehaviorSubject<MenuRc | null>(null);

  #menus = new BehaviorSubject<MenuRc[]>([]);
  menus$ = this.#menus.asObservable();

  constructor(private notifyService: NotifyService, private menuApi: MenuApi, private productApi: ProductApi) {}

  setMenu(menu: MenuRc) {
    this.#menu.next(menu);
  }

  get menu() {
    return this.#menu.getValue();
  }

  addMenuRcStructure(variables: MutationAddMenuRcArgs): MutationResult<'addMenuRc'> {
    return this.menuApi.addMenuRcStructure(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при добавлении элементов',
      }),
    );
  }

  getAllMenusRc(variables: QueryAllRcMenusByStoreIdsPageableArgs): QueryResult<'menusRc'> {
    return this.menuApi.getAllMenusRc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка меню',
      }),
    );
  }

  getMenuRc(variables: QueryMenuRcArgs): QueryResult<'menuRc'> {
    return this.menuApi.getMenuRc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении меню',
      }),
    );
  }

  getAllInMenuPageable(variables: QueryMenuRcElementsArgs): QueryResult<'menuRcElements'> {
    return this.menuApi.getAllInMenuPageable(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении элементов меню',
      }),
    );
  }

  getMenuSectionsShort(variables: QueryMenuRcElementsArgs): QueryResult<'menuRcElements'> {
    return this.menuApi.getMenuSectionsShort(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении элементов меню',
      }),
    );
  }

  createRcMenu(variables: MutationCreateMenuRcArgs): MutationResult<'createMenuRc'> {
    return this.menuApi.createRcMenu(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при создании меню',
      }),
    );
  }

  deleteMenuRc(variables: MutationDeleteMenuRcArgs): MutationResult<'deleteMenuRc'> {
    return this.menuApi.deleteMenuRc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при удалении меню',
      }),
    );
  }

  createMenuSectionRc(variables: MutationCreateSectionRcArgs): MutationResult<'createSectionRc'> {
    return this.menuApi.createMenuSectionRc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при создании категории меню',
      }),
    );
  }

  updateMenuSectionRc(variables: MutationUpdateSectionRcArgs): MutationResult<'updateSectionRc'> {
    return this.menuApi.updateMenuSectionRc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при изменении категории меню',
      }),
    );
  }

  deleteMenuSectionRc(variables: MutationDeleteSectionRcArgs): MutationResult<'deleteSectionRc'> {
    return this.menuApi.deleteMenuSectionRc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при удалени категории меню',
      }),
    );
  }

  updateMenuItemRc(variables: MutationUpdateItemRcArgs): MutationResult<'updateItemRc'> {
    return this.menuApi.updateMenuItemRc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при изменении элемента меню',
      }),
    );
  }

  deleteMenuItemRc(variables: MutationDeleteItemRcArgs): MutationResult<'deleteItemRc'> {
    return this.menuApi.deleteMenuItemRc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при удалении позиции меню',
      }),
    );
  }

  duplicateMenuRc(variables: MutationDuplicateMenuRcArgs): MutationResult<'duplicateMenuRc'> {
    return this.menuApi.duplicateMenuRc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при дублировании меню',
      }),
    );
  }

  setStores(stores: Store[]): void {
    this.#stores.next(stores);
  }

  getStores(): Store[] {
    return this.#stores.getValue();
  }

  setMenus(menus: MenuRc[]): void {
    this.#menus.next(menus);
  }

  getAllMenus(variables: QueryAllMenusByStoreIdsPageableArgs): QueryResult<'allMenusByStoreIdsPageable'> {
    return this.menuApi.getAllMenus(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка меню',
      }),
    );
  }

  updateMenuRc(variables: MutationUpdateMenuRcArgs): MutationResult<'updateMenuRc'> {
    return this.menuApi.updateMenuRc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении меню',
      }),
    );
  }

  moveMenuElements(variables: MutationMoveElementsArgs): MutationResult<'moveElements'> {
    return this.menuApi.moveMenuElements(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при перемещении элементов меню',
      }),
    );
  }

  getMenuElementShort(variables: QueryMenuRcElementArgs): QueryResult<'menuRcElement'> {
    return this.menuApi.getMenuElementShort(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении элемента меню',
      }),
    );
  }

  getMenuElement(variables: QueryMenuRcElementArgs): QueryResult<'menuRcElement'> {
    return this.menuApi.getMenuElement(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении элемента меню',
      }),
    );
  }

  getMenuRcRootElements(variables: QueryMenuRcRootElementsArgs): QueryResult<'menuRcRootElements'> {
    return this.menuApi.getMenuRcRootElements(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении элементов меню',
      }),
    );
  }

  getMenuRcImmediateDescendants(variables: QueryMenuRcImmediateDescendantsArgs): QueryResult<'menuRcImmediateDescendants'> {
    return this.menuApi.getMenuRcImmediateDescendants(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении элементов меню',
      }),
    );
  }

  getPrimePriceAndImageForMenuItem(variables?: QueryStockUnitsArgs): QueryResult<'stockUnits'> {
    return this.menuApi.getPrimePriceAndImageForMenuItem(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении элементов меню',
      }),
    );
  }

  getMenuItemsBySection(variables: QueryAllMenuItemsBySectionArgs): QueryResult<'allMenuItemsBySection'> {
    return this.menuApi.getMenuItemsBySection(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении позиций меню',
      }),
    );
  }

  getAllProductsPageable(variables: QueryAllCatalogProductsPageableArgs): QueryResult<'allCatalogProductsPageable'> {
    return this.productApi.getAllProductsPageable(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка товаров',
      }),
    );
  }
}

import { Observable } from 'rxjs';

import { EntityWithId, Row, RowHeightEvent, RowVisibilityDirection } from '@app/shared/component/table/table.types';

import { InfiniteLoaderService, InfiniteLoaderState, InfiniteLoadFn } from '../infinite-loader/infinite-loader.service';

export class InfiniteLoaderTableService<T extends EntityWithId, F = never> {
  #infiniteLoaderService: InfiniteLoaderService<Row<T>, F> = new InfiniteLoaderService<Row<T>, F>();

  rows$: Observable<Row<T>[]>;
  state$: Observable<InfiniteLoaderState<Row<T>>>;
  scrollToItem$: Observable<string | undefined>;
  initiallyLoaded$: Observable<boolean>;

  infiniteLoadConfig = {
    loadMoreItems: (up?: boolean, keepPage?: boolean) => {
      this.#infiniteLoaderService.loadItems(up, keepPage);
    },
    onRowHidden: (event: RowVisibilityDirection<EntityWithId>) => {
      this.#infiniteLoaderService.onItemHidden(event.row as Row<T>, event.visibilityEvent);
    },
    onRowVisible: (event: RowVisibilityDirection<EntityWithId>) => {
      this.#infiniteLoaderService.onItemVisible(event.row as Row<T>, event.visibilityEvent);
    },
    onRowHeightInited: (event: RowHeightEvent<EntityWithId>) => {
      this.#infiniteLoaderService.onItemHeightInit(event.row as Row<T>, event.height);
    },
  };

  constructor() {}

  init(loadItemsFn: InfiniteLoadFn<Row<T>, F>, currentPage: number, pageSize?: number) {
    this.#infiniteLoaderService.init(
      {
        loadItemsFn,
        itemIdFn: (item: Row<T>) => item.data!.id,
        pageSize: pageSize || 20,
      },
      currentPage,
    );

    this.rows$ = this.#infiniteLoaderService.visibleItems$;
    this.state$ = this.#infiniteLoaderService.state$;
    this.scrollToItem$ = this.#infiniteLoaderService.scrollToItem$;
    this.initiallyLoaded$ = this.#infiniteLoaderService.initiallyLoaded$;
  }

  filter(filters: F) {
    this.#infiniteLoaderService.filter(filters);
  }

  destroy() {
    this.#infiniteLoaderService.destroy();
  }

  getTotalItems(): number {
    return this.#infiniteLoaderService.getTotalItems();
  }

  refresh() {
    return this.#infiniteLoaderService.refresh();
  }
  refreshAllPages() {
    return this.#infiniteLoaderService.refreshAll();
  }

  refreshItemPage(itemId: string) {
    this.#infiniteLoaderService.refreshItemPage(itemId);
  }

  refreshLastPage() {
    this.#infiniteLoaderService.refreshLastPage();
  }

  setPage(page: number): void {
    this.#infiniteLoaderService.setPage(page);
  }
  updateItem(id: string, newItem: Row<T, unknown>): void {
    this.#infiniteLoaderService.updateItem(id, newItem);
  }

  updateNameItem(id: string, name: string): void {
    const data: { name: string } = this.#infiniteLoaderService
      .getAllItems(this.#infiniteLoaderService.getState())
      .find((item) => item.rowId === id)
      ?.columnsData.find((c) => c.column === 'name')?.data as { name: string };
    if (data) {
      data.name = name;
    }
  }

  removeItem(id: string): void {
    this.#infiniteLoaderService.removeItemById(id);
  }
  updateRowsPositions(rows: Row[]): void {
    this.#infiniteLoaderService.updateRowsPositionsFromTable(rows);
  }
}

export class InfiniteLoaderTableServiceFactory {
  static getService<T extends EntityWithId, F = unknown>(
    loadItemsFn: InfiniteLoadFn<Row<T>, F>,
    currentPage: number,
    pageSize?: number,
  ): InfiniteLoaderTableService<T, F> {
    const service = new InfiniteLoaderTableService<T, F>();
    service.init(loadItemsFn, currentPage || 0, pageSize || 20);

    return service;
  }
}

import equal from 'fast-deep-equal';
import { BehaviorSubject, catchError, map, mergeMap, Observable, of, pairwise, shareReplay, Subject, take, takeUntil, tap } from 'rxjs';

import { EntityWithId, Row } from '@app/shared/component/table/table.types';
import { MoveDirection, ScrollVisibilityEvent } from '@app/shared/directive/scroll-visibility/scroll-visibility.directive';
import { PageRequestInput } from '@typings';

export type InfiniteLoadResult<T> = {
  total: number;
  totalPages: number;
  items: T[];
};

export type InfiniteLoadFn<T, F> = (pageRequest: PageRequestInput, filters?: F) => Observable<InfiniteLoadResult<T>>;
export type ItemIdFn<T> = (item: T) => string;

export type InfiniteLoaderPage<T> = {
  pageNumber: number;
  items: (T | Row<EntityWithId, unknown>)[];
  inViewport: boolean;
  displayed: boolean;
};

export type InfiniteLoaderState<T> = {
  canLoad: boolean;
  canLoadUp: boolean;
  loadingUp: boolean;
  loadingDown: boolean;
  reloading: boolean;
  currentPage: number | undefined;
  offsetTop: number;
  offsetBottom: number;
  pages: InfiniteLoaderPage<T>[];
  loadedDown?: boolean;
} & Omit<InfiniteLoadResult<T>, 'items'>;

export type InfiniteLoaderSettings<T, F> = {
  loadItemsFn: InfiniteLoadFn<T, F>;
  itemIdFn: ItemIdFn<T>;
  pageSize?: number;
  pagesBuffer?: number;
};

type LoaderParams<F> = { page?: number; filters?: F };

type ItemPosition = { page: number; isFirst: boolean; isLast: boolean };
type ItemsPositions = Record<string, ItemPosition>;
type PagesHeight = Record<number, Record<string, number>>;
export class InfiniteLoaderService<T, F = never> {
  private readonly initialState: InfiniteLoaderState<T> = {
    pages: [],
    total: 0,
    totalPages: 0,
    currentPage: undefined,
    offsetBottom: 0,
    offsetTop: 0,
    canLoad: true,
    canLoadUp: false,
    loadingUp: false,
    loadingDown: false,
    reloading: true,
  };

  initiallyLoaded$ = new BehaviorSubject<boolean>(false);

  #pagesBuffer: number = 1000;

  #destroy: Subject<void> = new Subject<void>();

  #isInitialized: boolean = false;
  #pageSize: number = 10;

  #filters = new BehaviorSubject<F | undefined>(undefined);
  #filters$ = this.#filters.asObservable();

  #page = new BehaviorSubject<number | undefined>(undefined);

  #loadItemsFn: InfiniteLoadFn<T, F>;
  #itemIdFn: ItemIdFn<T>;

  #itemsPositions: ItemsPositions = {};

  #pagesItemsHeights: PagesHeight = {};

  #state = new BehaviorSubject<InfiniteLoaderState<T>>({ ...this.initialState });

  forceReset: boolean = false;

  #keepPage: boolean = false;

  public readonly state$ = this.#state.asObservable().pipe(shareReplay());

  #scrollToItem = new BehaviorSubject<string | undefined>(undefined);
  public readonly scrollToItem$ = this.#scrollToItem.asObservable();

  #initialPage: number | undefined;

  public readonly visibleItems$: Observable<T[]> = this.state$.pipe(
    map((state) => this.getVisibleItems(state)),
    shareReplay(),
  );

  constructor() {}

  init(settings: InfiniteLoaderSettings<T, F>, currentPage?: number) {
    this.#loadItemsFn = settings.loadItemsFn;
    this.#itemIdFn = settings.itemIdFn;
    this.#updateCurrentPage(Number(currentPage));
    if (typeof currentPage === 'number' && !isNaN(currentPage)) {
      this.#initialPage = currentPage;
      this.#page.next(currentPage);
    }

    if (settings.pageSize) {
      this.#pageSize = settings.pageSize;
    }

    if (typeof settings.pagesBuffer === 'number') {
      this.#pagesBuffer = settings.pagesBuffer;
    }

    this.#filters$.pipe(takeUntil(this.#destroy), pairwise()).subscribe(([oldSearch, search]) => {
      if (this.forceReset || !equal(oldSearch, search)) {
        this.reset(this.#initialPage || 0);
        if (typeof this.#initialPage === 'number') {
          this.#initialPage = undefined;
        }
      }
    });

    this.#subscribeToPageChange();

    this.#isInitialized = true;
  }

  loadItems(up?: boolean, keepPage?: boolean): void {
    if (!this.#isInitialized) {
      throw Error('Service is not initialized. Call init() at first');
    }

    const state = this.#getState();
    if (up && state.canLoadUp) {
      this.#keepPage = !!keepPage;
      this.#updateState({ ...state, loadingUp: true });
      this.#nextPage(up);
    } else if (state.canLoad) {
      this.#updateState({ ...state, loadingDown: true });
      this.#nextPage();
    }
  }
  updateItem(id: string, newItem: Row<EntityWithId, unknown>) {
    let state = this.#getState();
    function traverseAndRemove(items: (T | Row<EntityWithId, unknown>)[]) {
      for (let i = items.length - 1; i >= 0; i--) {
        const item = items[i] as Row;

        if ((item as Row<EntityWithId, unknown>)?.data!.id === id) {
          //replace item, keep expanded children
          items[i] = { ...newItem, children: item.children, expanded: item.expanded };
        }
        if (item.children && item.children.length > 0) {
          item.children = traverseAndRemove(item.children) as Row[];
        }
      }
      return items;
    }
    state.pages = state.pages.map((p) => ({ ...p, items: traverseAndRemove(p.items) }));
    this.#updateState(state);
  }

  removeItemById(id: string) {
    let state = this.#getState();
    function traverseAndRemove(items: (T | Row<EntityWithId, unknown>)[]) {
      for (let i = items.length - 1; i >= 0; i--) {
        const item = items[i] as Row;

        if ((item as Row<EntityWithId, unknown>)?.data!.id === id) {
          items.splice(i, 1);
          return items;
        }
        if (item.children && item.children.length > 0) {
          item.children = traverseAndRemove(item.children) as Row[];
        }
      }
      return items;
    }
    state.pages = state.pages.map((p) => ({ ...p, items: traverseAndRemove(p.items) }));
    this.#state.next(state);
  }

  updateRowsPositionsFromTable(rows: Row[]) {
    const pageSize = this.#pageSize;

    let state = this.#getState();

    state.pages.forEach((page, index, pages) => {
      if (index === state.pages.length - 1) {
        pages[index].items = rows;
      }
      pages[index].items = rows.splice(0, pageSize);
    });
    this.#updateState(state);
  }

  reset(toPage: number | undefined): void {
    this.#resetState();
    this.#page.next(toPage || 0);
  }

  getVisibleItems(state: InfiniteLoaderState<T>): T[] {
    return state.pages.reduce((items: T[], page: InfiniteLoaderPage<T>) => {
      if (!page.displayed) {
        return items;
      }
      return items.concat(page.items as T[]);
    }, []);
  }

  getAllItems(state: InfiniteLoaderState<T>): T[] {
    return state.pages.reduce((items: T[], page: InfiniteLoaderPage<T>) => {
      return items.concat(page.items as T[]);
    }, []);
  }

  #subscribeToPageChange() {
    this.#page
      .pipe(
        takeUntil(this.#destroy),
        map((page) => ({
          filters: this.#getFilters(),
          page,
        })),
        pairwise(),
        mergeMap(([oldParams, newParams]) => {
          const currentState = this.#getState();
          const updated = this.#paramsUpdated(oldParams, newParams);
          const prevPage = oldParams.page;
          if (!this.forceReset && !updated) return of({ state: currentState, prevPage });
          if (this.forceReset) {
            this.forceReset = false;
          }
          const { page, filters } = newParams;
          const newPage: number = page || 0;

          return this.#loadItemsFn({ page: newPage, size: this.#pageSize }, filters).pipe(
            take(1),
            catchError((err) => {
              console.error(err);
              return of(null);
            }),
            map((res) => {
              if (!res) {
                return null;
              }
              return { state: this.#addPageToState(res, newPage, newPage < (prevPage || 0)), prevPage };
            }),
            tap((res) => {
              if (res) {
                this.initiallyLoaded$.next(true);
              }
            }),
          );
        }),
      )
      .subscribe((res) => {
        if (!res) {
          return;
        }

        const { state, prevPage } = res;

        if (this.#keepPage && prevPage) {
          this.#updateState({ ...state, currentPage: prevPage });
          this.setPage(prevPage);
          this.#keepPage = false;
        } else {
          this.#updateState(state);
        }
      });
  }

  #addPageToState(data: InfiniteLoadResult<T>, page: number, up: boolean): InfiniteLoaderState<T> {
    const { items, total, totalPages } = data;

    const currentState = this.#getState();
    let pages = [...currentState.pages];
    let existingIds: string[] = [];
    pages.map((page) =>
      page.items.map(
        (item) => 'rowDataId' in (item as unknown as object) && existingIds.push((item as Record<string, unknown>)['rowDataId'] as string),
      ),
    );
    let filteredItems = items.filter((item) => {
      if ('rowDataId' in (item as unknown as object)) {
        return !existingIds.includes((item as Record<string, unknown>)['rowDataId'] as string);
      } else {
        return true;
      }
    });

    const newPage = {
      items: filteredItems,
      inViewport: true,
      displayed: true,
      pageNumber: page,
    };

    if (pages.some((page) => page.pageNumber === newPage.pageNumber)) {
      this.#updatePageState(data, page);
      return currentState;
    }
    pages.push(newPage);
    pages.sort((a, b) => a.pageNumber - b.pageNumber);

    this.#updateItemsPositions(newPage);

    const state: InfiniteLoaderState<T> = {
      ...currentState,
      pages,
      total: total,
      totalPages: totalPages,
      canLoad: pages[pages.length - 1].pageNumber < totalPages - 1,
      canLoadUp: this.#canLoadUp(pages),
      currentPage: page || 0,
      reloading: false,
    };

    if (up) {
      state.loadingUp = false;
    } else {
      state.loadingDown = false;
    }
    return state;
  }

  #updatePageState(data: InfiniteLoadResult<T>, page: number): InfiniteLoaderState<T> {
    const { items, total, totalPages } = data;
    const currentState = this.#getState();
    const pages = [...currentState.pages];

    const pageIndex = this.#getPageIndexByNumber(pages, page);

    if (items.length === 0 && page === currentState.totalPages - 1) {
      const pageToRemove = { ...pages[pageIndex] };
      pages.pop();
      this.#removeItemsPositions(pageToRemove);
    } else {
      const newPage = {
        items: items,
        inViewport: true,
        displayed: true,
        pageNumber: page,
      };

      pages.splice(pageIndex, 1, newPage);

      this.#updateItemsPositions(newPage);
    }
    const canLoad = pages[pages.length - 1]?.pageNumber < totalPages - 1;
    const canLoadUp = this.#canLoadUp(pages);

    const state: InfiniteLoaderState<T> = {
      ...currentState,
      pages,
      total: total,
      canLoad,
      canLoadUp,
      totalPages: totalPages,
      reloading: false,
    };

    return state;
  }

  #paramsUpdated(oldParams: LoaderParams<F>, newParams: LoaderParams<F>): boolean {
    return oldParams.page !== newParams.page || oldParams.filters !== newParams.filters;
  }

  #getState(): InfiniteLoaderState<T> {
    return this.#state.getValue();
  }

  getTotalItems(): number {
    return this.#getState().total;
  }

  #getTotalPages(): number {
    return this.#getState().totalPages;
  }

  #updateState(state: InfiniteLoaderState<T>): void {
    this.#state.next(state);
  }

  #getFilters(): F | undefined {
    return this.#filters.getValue();
  }

  #nextPage(up?: boolean): void {
    const page = this.#getState().currentPage;
    let nextPage: number;
    if (up) {
      nextPage = !page || page <= 0 ? 0 : page - 1;
    } else {
      nextPage = !!page || page === 0 ? page + 1 : 0;
    }
    this.#page.next(nextPage);
  }

  #resetState(): void {
    this.#updateState({ ...this.initialState, canLoad: false });
    this.#itemsPositions = {};
  }

  destroy() {
    this.#destroy.next();
    this.#destroy.complete();
  }

  filter(filters: F, force?: boolean) {
    if (!this.#isInitialized) {
      throw Error('Service is not initialized. Call init() at first');
    }
    this.forceReset = !!force;
    this.#filters.next(filters);
  }

  #getPageIndexByNumber(pages: InfiniteLoaderPage<T>[], pageNumber: number): number {
    return pages.findIndex((page) => page.pageNumber === pageNumber);
  }

  #updateInViewportState(pageNumber: number, inViewport: boolean, updateDisplayed?: boolean): void {
    let state = this.#getState();
    const pageIndex = this.#getPageIndexByNumber(state.pages, pageNumber);
    if (pageIndex !== -1) {
      state.pages.splice(pageIndex, 1, { ...state.pages[pageIndex], inViewport });
    }
    if (updateDisplayed) {
      state = this.#updateDisplayedPages(state, pageNumber);
    }
    this.#updateState(state);
  }

  #updateCurrentPage(currentPage: number): void {
    const state = this.#getState();
    this.#updateState({ ...state, currentPage });
  }

  #updateItemsPositions(page: InfiniteLoaderPage<T>): void {
    const lastIndex = page.items.length - 1;
    page.items.forEach((item, index) => {
      this.#itemsPositions[this.#itemIdFn(item as T)] = {
        page: page.pageNumber,
        isFirst: index === 0,
        isLast: index === lastIndex,
      };
    });
  }

  #removeItemsPositions(page: InfiniteLoaderPage<T>): void {
    page.items.forEach((item) => {
      const position = this.#itemsPositions[this.#itemIdFn(item as T)];
      if (position.page === page.pageNumber) {
        delete this.#itemsPositions[this.#itemIdFn(item as T)];
      }
    });
  }

  #isUpDirection(direction: MoveDirection): boolean {
    return direction === 'up';
  }

  #isDownDirection(direction: MoveDirection): boolean {
    return direction === 'down';
  }

  #pageAppearedFromBottom(position: ItemPosition, direction: MoveDirection): boolean {
    return position?.isFirst && this.#isUpDirection(direction);
  }

  #pageAppearedFromTop(position: ItemPosition, direction: MoveDirection): boolean {
    return position?.isLast && this.#isDownDirection(direction);
  }

  #pageHiddenDown(position: ItemPosition, direction: MoveDirection): boolean {
    return position.isFirst && this.#isDownDirection(direction);
  }

  #pageHiddenUp(position: ItemPosition, direction: MoveDirection): boolean {
    return position.isLast && this.#isUpDirection(direction);
  }

  #updateDisplayedPages(prevState: InfiniteLoaderState<T>, currentPage: number | undefined): InfiniteLoaderState<T> {
    if (typeof currentPage !== 'number') return prevState;
    const pages = [...prevState.pages];

    const rangeStart = currentPage - this.#pagesBuffer;
    const rangeEnd = currentPage + this.#pagesBuffer;

    let { offsetTop, offsetBottom } = prevState;

    pages.forEach((page, i) => {
      const { pageNumber } = page;
      if (!page.displayed && pageNumber >= rangeStart && pageNumber <= rangeEnd) {
        //display page
        pages[i].displayed = true;
        if (pageNumber < currentPage) {
          offsetTop -= this.#getPageHeight(pageNumber);
        } else {
          offsetBottom -= this.#getPageHeight(pageNumber);
        }
      } else if (page.displayed && !page.inViewport && (pageNumber < rangeStart || pageNumber > rangeEnd)) {
        //hide page
        pages[i].displayed = false;
        if (pageNumber < rangeStart) {
          offsetTop += this.#getPageHeight(pageNumber);
        } else {
          offsetBottom += this.#getPageHeight(pageNumber);
        }
      }
    });

    return {
      ...prevState,
      pages,
      offsetTop,
      offsetBottom,
    };
  }

  #refreshPage(pageNumber: number): Promise<void> {
    return new Promise((resolve) => {
      this.#loadItemsFn({ page: pageNumber, size: this.#pageSize }, this.#getFilters())
        .pipe(
          take(1),
          map((res) => {
            return this.#updatePageState(res, pageNumber);
          }),
        )
        .subscribe((state) => {
          this.#updateState(state);
          resolve();
        });
    });
  }

  #updatePageItemHeight(pageNumber: number, itemId: string, height: number): void {
    let itemsHeights = this.#pagesItemsHeights[pageNumber];
    if (!itemsHeights) {
      itemsHeights = {};
    }
    itemsHeights[itemId] = height;

    this.#pagesItemsHeights[pageNumber] = itemsHeights;
  }

  #getPageHeight(pageNumber: number): number {
    const itemsHeights = this.#pagesItemsHeights[pageNumber];
    if (!itemsHeights) return 0;
    return Object.entries(itemsHeights).reduce((acc, item) => {
      acc += item[1];
      return acc;
    }, 0);
  }

  onItemHidden(item: T, event: ScrollVisibilityEvent): void {
    const { direction } = event;
    const position = this.#itemsPositions[this.#itemIdFn(item)];

    if (position) {
      const pageNumber = position.page;

      const isHiddenDown = this.#pageHiddenDown(position, direction);
      const isHiddenUp = this.#pageHiddenUp(position, direction);

      if (isHiddenDown || isHiddenUp) {
        this.#updateInViewportState(pageNumber, false);
      }
    }
  }

  onItemVisible(item: T, event: ScrollVisibilityEvent): void {
    const { direction } = event;
    const position = this.#itemsPositions[this.#itemIdFn(item)];

    if (position) {
      const pageNumber = position.page;

      const pageFromBottom = this.#pageAppearedFromBottom(position, direction);
      const pageFromTop = this.#pageAppearedFromTop(position, direction);

      if (pageFromBottom || pageFromTop) {
        this.#updateCurrentPage(pageNumber);
        this.#updateInViewportState(pageNumber, true, true);
      }
    }
  }

  onItemHeightInit(item: T, height: number): void {
    const itemId = this.#itemIdFn(item);
    const position = this.#itemsPositions[itemId];
    if (position) {
      this.#updatePageItemHeight(position.page, itemId, height);
    }
  }

  refreshItemPage(itemId: string) {
    const pageNumber = this.#itemsPositions[itemId]?.page || 0;
    this.#refreshPage(pageNumber);
  }

  refreshLastPage() {
    const lastPage = this.#state.getValue().pages.length - 1;
    this.#refreshPage(lastPage);
  }

  refresh() {
    this.#refreshPage(0);
  }

  refreshAll() {
    let promise = Promise.resolve();
    const pagesCount = this.#getTotalPages() - 1;

    for (let i = 0; i <= pagesCount; i++) {
      promise = promise.then(() => this.#refreshPage(i));
    }
  }

  #pageIsLoaded(pages: InfiniteLoaderPage<T>[], page: number) {
    return !!pages.some((p) => p.pageNumber === page);
  }

  #canLoadUp(pages: InfiniteLoaderPage<T>[]): boolean {
    return !this.#pageIsLoaded(pages, 0);
  }

  setPage(pageNumber: number): void {
    if (pageNumber < 0 || pageNumber >= this.#getTotalPages()) return;

    const state = this.#getState();
    const displayedPage = state.pages.find((p) => p.displayed && p.pageNumber === pageNumber);
    //somehow update scroll position of upper/lower items to get right scroll direction
    if (!!displayedPage) {
      this.#updateCurrentPage(pageNumber);
      this.#scrollToItem.next(this.#itemIdFn((displayedPage.items as T[])[0]));
      return;
    }

    const displayedPages = state.pages.filter((p) => p.displayed);

    if (displayedPages[0].pageNumber === pageNumber + 1) {
      this.#scrollToItem.next('top');
      return;
    } else if (displayedPages[displayedPages.length - 1].pageNumber === pageNumber - 1) {
      this.#scrollToItem.next('bottom');
      return;
    }
    this.#resetState();
    this.#page.next(pageNumber);
  }

  getState() {
    return this.#state.getValue();
  }
}

import { DateRange } from '@angular/material/datepicker';
import dayjs from 'dayjs';

import { DATE_FORMAT, DATE_FORMAT_VIEW, DAY_OF_WEEK_MAP } from '@constants';
import { CoreSchema, DateRangeInput, DayOfWeek } from '@typings';

export const formatDate = (date: Date | string, format: string = DATE_FORMAT): string => {
  return dayjs(date).format(format);
};

export const getPeriodString = (period: DateRange<Date> | null): string => {
  if (period && period.start && period.end) {
    return `${formatDate(period.start, DATE_FORMAT_VIEW)}–${formatDate(period.end, DATE_FORMAT_VIEW)}`;
  }
  return '';
};

export const periodToString = (period: DateRange<Date>): string => {
  if (period && period.start && period.end) {
    return `Период: ${getPeriodString(period)}`;
  }
  return '';
};

export const dateRangeToString = (period: DateRangeInput): string => {
  if (period.dateFrom && period.dateTo) {
    return `Период: ${formatDate(period.dateFrom, DATE_FORMAT_VIEW)}–${formatDate(period.dateTo, DATE_FORMAT_VIEW)}`;
  }
  return '';
};

export const getDateGroupingByPeriod = (range: DateRange<Date> | null, days?: DayOfWeek[]): CoreSchema.DateGrouping => {
  if (!range || !range.end || !range.start) return 'DAY';

  if (!!days && !!range && getHourGroupingByDayOfWeek(range, days)) return 'HOUR';

  const diffHour = dayjs(range.end).diff(range.start, 'hour');
  if (diffHour < 24) return 'HOUR';

  const diffMonth = dayjs(range.end).diff(range.start, 'month');
  if (diffMonth < 2) return 'DAY';

  return 'MONTH';
};

export const getHourGroupingByDayOfWeek = (range: DateRange<Date>, days: DayOfWeek[]): boolean => {
  let remainingDays = 0;

  for (let current = new Date(range.start!); current <= range.end!; current.setDate(current.getDate() + 1)) {
    const currentDayOfWeek = DAY_OF_WEEK_MAP[current.getDay()];
    if (new Set(days).has(currentDayOfWeek)) {
      remainingDays++;
      if (remainingDays > 1) {
        return false;
      }
    }
  }

  return remainingDays === 1;
};

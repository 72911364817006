import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[nmAutofocus]',
})
export class AutofocusDirective implements AfterViewInit {
  constructor(private element: ElementRef) {}

  ngAfterViewInit(): void {
    const myInputField = this.element.nativeElement.querySelector('.my-input__field');

    if (myInputField) {
      setTimeout(() => myInputField.focus(), 300);
    } else {
      setTimeout(() => {
        this.element.nativeElement.focus();
      }, 100);
    }
  }
}

import { DayOfWeek, DayOfWeekStrict, DayOfWeekString } from '@typings';

export const DAY_OF_WEEK: DayOfWeekStrict = {
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
  SUNDAY: 'SUNDAY',
};

export const DAY_OF_WEEK_MAP: Record<number, DayOfWeek> = {
  0: 'SUNDAY',
  1: 'MONDAY',
  2: 'TUESDAY',
  3: 'WEDNESDAY',
  4: 'THURSDAY',
  5: 'FRIDAY',
  6: 'SATURDAY',
};

export const DAY_OF_WEEK_STRING: DayOfWeekString = {
  MONDAY: 'Понедельник',
  TUESDAY: 'Вторник',
  WEDNESDAY: 'Среда',
  THURSDAY: 'Четверг',
  FRIDAY: 'Пятница',
  SATURDAY: 'Суббота',
  SUNDAY: 'Воскресенье',
};

export const DAY_OF_WEEK_STRING_SHORT: DayOfWeekString = {
  MONDAY: 'Пн',
  TUESDAY: 'Вт',
  WEDNESDAY: 'Ср',
  THURSDAY: 'Чт',
  FRIDAY: 'Пт',
  SATURDAY: 'Сб',
  SUNDAY: 'Вс',
};
export type Days = {
  id: DayOfWeek;
  name: string;
};

export const allWeekdays = [
  DAY_OF_WEEK.MONDAY,
  DAY_OF_WEEK.TUESDAY,
  DAY_OF_WEEK.WEDNESDAY,
  DAY_OF_WEEK.THURSDAY,
  DAY_OF_WEEK.FRIDAY,
  DAY_OF_WEEK.SATURDAY,
  DAY_OF_WEEK.SUNDAY,
];

export const DAYS_OF_WEEK: Days[] = [
  {
    id: DAY_OF_WEEK.MONDAY,
    name: 'Пн',
  },
  {
    id: DAY_OF_WEEK.TUESDAY,
    name: 'Вт',
  },
  {
    id: DAY_OF_WEEK.WEDNESDAY,
    name: 'Ср',
  },
  {
    id: DAY_OF_WEEK.THURSDAY,
    name: 'Чт',
  },
  {
    id: DAY_OF_WEEK.FRIDAY,
    name: 'Пт',
  },
  {
    id: DAY_OF_WEEK.SATURDAY,
    name: 'Сб',
  },
  {
    id: DAY_OF_WEEK.SUNDAY,
    name: 'Вс',
  },
];

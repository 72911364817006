import { Injectable } from '@angular/core';

import {
  getPrimePriceAndImageForMenuItem,
  mutationAddMenuRc,
  mutationCreateMenuRc,
  mutationCreateMenuSectionRc,
  mutationDeleteMenuItemRc,
  mutationDeleteMenuRc,
  mutationDeleteMenuSectionRc,
  mutationDuplicateMenuRc,
  mutationMoveElements,
  mutationUpdateMenuItemRc,
  mutationUpdateMenuRc,
  mutationUpdateMenuSectionRc,
  queryAllInMenuPageable,
  queryAllMenuItemsBySection,
  queryAllMenusByStoreIdsPageable,
  queryMenuElement,
  queryMenuElementShort,
  queryMenuElementsShort,
  queryMenuRc,
  queryMenuRcImmediateDescendants,
  queryMenuRcRootElements,
} from '@api';
import { ApiService } from '@core/service';
import {
  Mutation,
  MutationAddMenuRcArgs,
  MutationCreateMenuRcArgs,
  MutationCreateSectionRcArgs,
  MutationDeleteItemRcArgs,
  MutationDeleteMenuRcArgs,
  MutationDeleteSectionRcArgs,
  MutationDuplicateMenuRcArgs,
  MutationMoveElementsArgs,
  MutationResult,
  MutationUpdateItemRcArgs,
  MutationUpdateMenuRcArgs,
  MutationUpdateSectionRcArgs,
  Query,
  QueryAllMenuItemsBySectionArgs,
  QueryAllMenusByStoreIdsPageableArgs,
  QueryAllRcMenusByStoreIdsPageableArgs,
  QueryMenuRcArgs,
  QueryMenuRcElementArgs,
  QueryMenuRcElementsArgs,
  QueryMenuRcImmediateDescendantsArgs,
  QueryMenuRcRootElementsArgs,
  QueryResult,
  QueryStockUnitsArgs,
} from '@typings';

@Injectable({
  providedIn: 'root',
})
export class MenuApi {
  constructor(private apiService: ApiService) {}

  getAllMenusRc(variables: QueryAllRcMenusByStoreIdsPageableArgs): QueryResult<'menusRc'> {
    return this.apiService.query<Query<'menusRc'>, QueryAllRcMenusByStoreIdsPageableArgs>({
      query: queryAllMenusByStoreIdsPageable,
      variables,
    });
  }

  getMenuRc(variables: QueryMenuRcArgs): QueryResult<'menuRc'> {
    return this.apiService.query<Query<'menuRc'>, QueryMenuRcArgs>({
      query: queryMenuRc,
      variables,
    });
  }

  getAllInMenuPageable(variables: QueryMenuRcElementsArgs): QueryResult<'menuRcElements'> {
    return this.apiService.query<Query<'menuRcElements'>, QueryMenuRcElementsArgs>({
      query: queryAllInMenuPageable,
      variables,
    });
  }

  getMenuSectionsShort(variables: QueryMenuRcElementsArgs): QueryResult<'menuRcElements'> {
    return this.apiService.query<Query<'menuRcElements'>, QueryMenuRcElementsArgs>({
      query: queryMenuElementsShort,
      variables,
    });
  }

  createRcMenu(variables: MutationCreateMenuRcArgs): MutationResult<'createMenuRc'> {
    return this.apiService.mutate<Mutation<'createMenuRc'>, MutationCreateMenuRcArgs>({
      mutation: mutationCreateMenuRc,
      variables,
    });
  }

  getAllMenus(variables: QueryAllMenusByStoreIdsPageableArgs): QueryResult<'allMenusByStoreIdsPageable'> {
    return this.apiService.query<Query<'allMenusByStoreIdsPageable'>, QueryAllMenusByStoreIdsPageableArgs>({
      query: queryAllMenusByStoreIdsPageable,
      variables,
    });
  }

  addMenuRcStructure(variables: MutationAddMenuRcArgs): MutationResult<'addMenuRc'> {
    return this.apiService.mutate<Mutation<'addMenuRc'>, MutationAddMenuRcArgs>({
      mutation: mutationAddMenuRc,
      variables,
    });
  }

  deleteMenuRc(variables: MutationDeleteMenuRcArgs): MutationResult<'deleteMenuRc'> {
    return this.apiService.mutate<Mutation<'deleteMenuRc'>, MutationDeleteMenuRcArgs>({
      mutation: mutationDeleteMenuRc,
      variables,
    });
  }

  updateMenuRc(variables: MutationUpdateMenuRcArgs): MutationResult<'updateMenuRc'> {
    return this.apiService.mutate<Mutation<'updateMenuRc'>, MutationUpdateMenuRcArgs>({
      mutation: mutationUpdateMenuRc,
      variables,
    });
  }

  duplicateMenuRc(variables: MutationDuplicateMenuRcArgs): MutationResult<'duplicateMenuRc'> {
    return this.apiService.mutate<Mutation<'duplicateMenuRc'>, MutationDuplicateMenuRcArgs>({
      mutation: mutationDuplicateMenuRc,
      variables,
    });
  }

  createMenuSectionRc(variables: MutationCreateSectionRcArgs): MutationResult<'createSectionRc'> {
    return this.apiService.mutate<Mutation<'createSectionRc'>, MutationCreateSectionRcArgs>({
      mutation: mutationCreateMenuSectionRc,
      variables,
    });
  }

  updateMenuSectionRc(variables: MutationUpdateSectionRcArgs): MutationResult<'updateSectionRc'> {
    return this.apiService.mutate<Mutation<'updateSectionRc'>, MutationUpdateSectionRcArgs>({
      mutation: mutationUpdateMenuSectionRc,
      variables,
    });
  }

  deleteMenuSectionRc(variables: MutationDeleteSectionRcArgs): MutationResult<'deleteSectionRc'> {
    return this.apiService.mutate<Mutation<'deleteSectionRc'>, MutationDeleteSectionRcArgs>({
      mutation: mutationDeleteMenuSectionRc,
      variables,
    });
  }

  moveMenuElements(variables: MutationMoveElementsArgs): MutationResult<'moveElements'> {
    return this.apiService.mutate<Mutation<'moveElements'>, MutationMoveElementsArgs>({
      mutation: mutationMoveElements,
      variables,
    });
  }

  updateMenuItemRc(variables: MutationUpdateItemRcArgs): MutationResult<'updateItemRc'> {
    return this.apiService.mutate<Mutation<'updateItemRc'>, MutationUpdateItemRcArgs>({
      mutation: mutationUpdateMenuItemRc,
      variables,
    });
  }
  deleteMenuItemRc(variables: MutationDeleteItemRcArgs): MutationResult<'deleteItemRc'> {
    return this.apiService.mutate<Mutation<'deleteItemRc'>, MutationDeleteItemRcArgs>({
      mutation: mutationDeleteMenuItemRc,
      variables,
    });
  }

  getMenuElementShort(variables: QueryMenuRcElementArgs): QueryResult<'menuRcElement'> {
    return this.apiService.query<Query<'menuRcElement'>, QueryMenuRcElementArgs>({
      query: queryMenuElementShort,
      variables,
    });
  }

  getMenuElement(variables: QueryMenuRcElementArgs): QueryResult<'menuRcElement'> {
    return this.apiService.query<Query<'menuRcElement'>, QueryMenuRcElementArgs>({
      query: queryMenuElement,
      variables,
    });
  }

  getMenuRcRootElements(variables: QueryMenuRcRootElementsArgs): QueryResult<'menuRcRootElements'> {
    return this.apiService.query<Query<'menuRcRootElements'>, QueryMenuRcRootElementsArgs>({
      query: queryMenuRcRootElements,
      variables,
    });
  }

  getMenuRcImmediateDescendants(variables: QueryMenuRcImmediateDescendantsArgs): QueryResult<'menuRcImmediateDescendants'> {
    return this.apiService.query<Query<'menuRcImmediateDescendants'>, QueryMenuRcImmediateDescendantsArgs>({
      query: queryMenuRcImmediateDescendants,
      variables,
    });
  }

  getPrimePriceAndImageForMenuItem(variables?: QueryStockUnitsArgs): QueryResult<'stockUnits'> {
    return this.apiService.query<Query<'stockUnits'>, QueryStockUnitsArgs>({
      query: getPrimePriceAndImageForMenuItem,
      variables,
    });
  }

  getMenuItemsBySection(variables: QueryAllMenuItemsBySectionArgs): QueryResult<'allMenuItemsBySection'> {
    return this.apiService.query<Query<'allMenuItemsBySection'>, QueryAllMenuItemsBySectionArgs>({
      query: queryAllMenuItemsBySection,
      variables,
    });
  }
}

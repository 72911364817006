<div
  [attr.data-test-id]="testId"
  [ngClass]="componentClasses"
  [style.border-radius]="isMobile ? '0' : '0 16px 16px 0'"
  [style.width]="isMobile ? '100%' : ''"
>
  <div class="header" *ngrxLet="logoUrl as logo">
    <img class="logomark" [src]="logoMarkUrl" alt="logomark." />

    <img class="logo" [src]="logo" alt="logo." />

    <nm-icon-button
      class="collapse-btn"
      variant="text"
      [icon]="toggleIconName"
      (click)="toggleMode($event)"
      data-test-id="sidebar::toggle-button"
    ></nm-icon-button>

    <nm-icon-button
      *ngIf="isMobile"
      variant="text"
      [icon]="'close'"
      (click)="closeSidenav.emit()"
      data-test-id="sidebar::toggle-button"
    ></nm-icon-button>
  </div>

  <div class="content">
    <mat-accordion #accordion [style.width]="isMobile ? '100%' : ''">
      <nm-dropdown-menu
        #orgsMenu
        [positions]="orgMenuPositions"
        [initView]="orgTemplate"
        (menuOpened)="setDropdownState(true)"
        (menuClosed)="setDropdownState(false)"
        data-test-id="sidebar::organizations-menu-button"
      >
        <div class="active-organization" [class.collapsed]="isCollapsed" *ngrxLet="activeOrg$ as activeOrg">
          <div class="container">
            <nm-avatar
              class="item-icon"
              type="square"
              [title]="activeOrg[0]?.name ? activeOrg[0]?.name : 'N O'"
            ></nm-avatar>
            <div>
              <div class="item-title account-name">
                {{ activeOrg[0]?.name ? activeOrg[0]?.name : 'ID: ' + activeOrg[0]?.shortId }}
              </div>
              <div class="item-description account-name">ID: {{ activeOrg[0]?.shortId }}</div>
            </div>
          </div>
          <nm-svg-icon
            data-test-id="sidebar::icon-profile-arrow-drop-right"
            class="item-icon organization-arrow"
            [size]="24"
            name="keyboardArrowRight"
          ></nm-svg-icon>
        </div>
      </nm-dropdown-menu>

      <nm-divider></nm-divider>

      <ng-template #orgTemplate>
        <nm-menu-content
          class="organizations-list"
          [selectedItems]="selectedOrg$ | async"
          (itemClick)="setOrganization($event)"
          [items]="organizations$ | async"
        >
        </nm-menu-content>
      </ng-template>

      <mat-expansion-panel
        class="nm-expansion-panel"
        [ngClass]="{ 'no-expansion': !item.routes }"
        *ngFor="let item of items"
        [hideToggle]="item.hideRoutes || !item.routes || isCollapsed"
        [expanded]="!isCollapsed && isClickRoute(item) && isSelectedRoute(item)"
        (click)="clickOnRootRoute(item)"
        [disabled]="!isRouteAllowed(item)"
      >
        <mat-expansion-panel-header
          [attr.data-test-id]="getNavItemTestId(item)"
          [ngClass]="{ selected: isSelectedRoute(item), selectedTotal: isSelectedTotalRoute(item) }"
        >
          <mat-panel-title>
            <nm-svg-icon
              [attr.data-test-id]="getNavItemIconTestId(item)"
              class="item-icon"
              [size]="24"
              [name]="item.icon"
            ></nm-svg-icon>
            <span class="item-title" [class.nav-item-disabled]="!isRouteAllowed(item)">{{ item.title }}</span>
          </mat-panel-title>
          <nm-svg-icon
            *ngIf="item.iconRight && !isCollapsed"
            [attr.data-test-id]="getNavItemIconTestId(item)"
            class="item-icon"
            [style.margin-right]="'-16px'"
            [size]="24"
            [name]="item.iconRight"
            (click)="item.onRightClick(accountStorage, sessionStorage.getOrgId(), redirectService)"
          ></nm-svg-icon>
        </mat-expansion-panel-header>
        <mat-nav-list *ngIf="!item.hideRoutes">
          <ng-container *ngFor="let routeItems of item.routes">
            <ng-container *ngIf="!routeItems.isSubTitle">
              <mat-list-item
                [ngClass]="{ selected: isCurrentItem(routeItems) }"
                [disableRipple]="true"
                (click)="setActiveItem(routeItems)"
                [attr.data-test-id]="getNavChildItemTestId(item, routeItems)"
              >
                <span class="item-title">{{ routeItems.title }}</span>
              </mat-list-item>
            </ng-container>

            <ng-container *ngIf="routeItems.isSubTitle">
              <div>
                <div class="sub-route-title" (click)="routeItems.isSubRouteClose = !routeItems.isSubRouteClose">
                  <span>{{ routeItems.title }}</span>
                  <span>{{ routeItems.routes.length }}</span>
                </div>

                <ng-container *ngIf="routeItems.isSubRouteClose || !routeItems.isSubTitle">
                  <mat-list-item
                    *ngFor="let routeItem of routeItems.routes"
                    [ngClass]="{ selected: isCurrentItem(routeItem) }"
                    [disableRipple]="true"
                    (click)="setActiveItem(routeItem)"
                    [attr.data-test-id]="getNavChildItemTestId(routeItems, routeItem)"
                  >
                    <span class="item-title">{{ routeItem.title }}</span>
                  </mat-list-item>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </mat-nav-list>
      </mat-expansion-panel>
    </mat-accordion>

    <nm-micro-banner
      *ngIf="showSubscriptionBanner && !this.isCollapsed"
      [isClose]="true"
      [title]="subscriptionBannerTitle"
      [description]="subscriptionBannerDescription"
      [type]="subscriptionBannerType"
      linkText="Продлить подписку"
      [redirectPage]="true"
      (clickRedirect)="subscriptionAlertsService.clickToSubscription()"
      (closeBanner)="closeSubscriptionBanner()"
    ></nm-micro-banner>
  </div>
  <div class="footer">
    <nm-dropdown-menu
      #profileDropdown
      class="filter-checkbox"
      [positions]="profileMenuPositions"
      [initView]="menuTemplate"
      (menuOpened)="setDropdownState(true)"
      (menuClosed)="setDropdownState(false)"
      data-test-id="sidebar::profile-button"
    >
      <div class="profile" [class.collapsed]="isCollapsed">
        <nm-avatar
          class="item-icon"
          type="round"
          [imageUrl]="navigationDrawerService.avatarUrl.getValue() || ''"
          [title]="accountName"
        ></nm-avatar>
        <div class="item-title account-name" [title]="accountName">{{ accountName }}</div>
        <nm-svg-icon
          data-test-id="sidebar::icon-profile-arrow-drop-right"
          class="item-icon profile-arrow"
          [size]="24"
          name="keyboardArrowRight"
        ></nm-svg-icon>
      </div>
    </nm-dropdown-menu>

    <ng-template #menuTemplate>
      <nm-menu-content class="profile-panel" [items]="profileButtons" [header]="profileHeader" style="width: 280px">
        <div class="profile-dropdown">
          Если у&nbsp;вас возникли трудности, позвоните техподдержке:
          <a class="profile-phone" href="tel:88007778967">8-800-777-8967 (доб.2)</a>.
          <br />
          Мы&nbsp;работаем по&nbsp;будням с&nbsp;8&nbsp;до&nbsp;18:00&nbsp;МСК.
          <br /><br />
          ID организации: {{ activeOrg?.shortId }}
        </div>
      </nm-menu-content>
    </ng-template>
  </div>
</div>

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AutocompleteComponent } from '@app/shared/component/autocomplete/autocomplete.component';
import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { SearchAutocompleteServiceFactory } from '@app/shared/service/autocomplete/search-autocomplete.service';
import { MenuService } from '@services/catalog';
import { StoresService } from '@services/settings';
import { SearchAutocompleteService } from '@services/shared';
import { MenuRc, Store } from '@typings';

@Component({
  selector: 'nm-menu-duplicate-dialog',
  templateUrl: './menu-duplicate-dialog.component.html',
  styleUrls: ['./menu-duplicate-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuDuplicateDialogComponent {
  searchStoresService: SearchAutocompleteService<Store>;

  get controls() {
    return this.menuService.duplicateForm.controls;
  }

  get storeIdsControl() {
    return this.controls.storeIds;
  }

  get isStoreIdsError() {
    return this.storeIdsControl.dirty && this.storeIdsControl.invalid;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { menu: MenuRc },
    public menuService: MenuService,
    private storesService: StoresService,
  ) {
    this.menuService.initDuplicateForm();
    this.searchStoresService = SearchAutocompleteServiceFactory.getService(this.#searchStoresFn);
  }

  #searchStoresFn = (searchText: string): Observable<AutocompleteOption<Store>[]> => {
    return this.storesService.getStoresForSearch(searchText).pipe(
      map((stores) =>
        stores.map((store) => ({
          label: store.name,
          type: 'item',
          id: store.id,
          data: store,
        })),
      ),
    );
  };

  cancel() {
    this.menuService.closeDuplicateForm(false);
  }
  openStoreAutocomplete(autocompleteComponent: AutocompleteComponent) {
    autocompleteComponent.searchFn?.('');
  }
  confirm() {
    this.menuService.closeDuplicateForm(true);
  }
}

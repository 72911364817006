import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { MenuItem, MenuItemType } from '@app/shared/component/menu-item/menu-item.model';
import { NotifyService } from '@services/shared';
import {
  MutationCreateStoreV2Args,
  MutationResult,
  MutationUpdateStoreArgs,
  QueryResult,
  QueryStoreArgs,
  QueryStoresArgs,
  Store,
} from '@typings';

import { StoresApi } from './stores.api';

@Injectable({
  providedIn: 'root',
})
export class StoresStorage {
  #stores = new BehaviorSubject<MenuItem[]>([]);
  stores$ = this.#stores.asObservable();

  updateStores(value: MenuItem[]): void {
    this.#stores.next(value);
  }

  constructor(private storesApi: StoresApi, private notifyService: NotifyService) {}

  async initAllStores() {
    return new Promise((resolve) => {
      this.storesApi
        .getAllStores()
        .pipe(take(1))
        .subscribe((stores) => {
          const items = stores.data.allStores.map((store) => ({
            id: store.id,
            type: 'item' as MenuItemType,
            label: store.name,
          }));
          this.updateStores(items);
          resolve(stores);
        });
    });
  }

  getAllStores(): QueryResult<'allStores'> {
    return this.storesApi.getAllStores().pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка точек продаж',
      }),
    );
  }

  getAllStoresShort(): QueryResult<'allStores'> {
    return this.storesApi.getAllStoresShort().pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка точек продаж',
      }),
    );
  }

  getStores(variables: QueryStoresArgs): QueryResult<'stores'> {
    return this.storesApi.getStores(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка точек продаж',
      }),
    );
  }

  getStoresShort(variables: QueryStoresArgs): QueryResult<'stores'> {
    return this.storesApi.getStoresShort(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка точек продаж',
      }),
    );
  }

  getAllStoresForPaymentSystem(): Observable<Store[]> {
    return this.storesApi.allStoresForPaymentSystem().pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка точек продаж',
      }),
      map((res) => res.data.allStores),
    );
  }

  getStoresTotalNumber(variables: QueryStoresArgs): QueryResult<'stores'> {
    return this.storesApi.getStoresTotalNumber(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка точек продаж',
      }),
    );
  }

  getStore(variables: QueryStoreArgs): QueryResult<'store'> {
    return this.storesApi.getStore(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении точки продаж',
      }),
    );
  }

  createStore(variables: MutationCreateStoreV2Args): MutationResult<'createStoreV2'> {
    return this.storesApi.createStore(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при создании точки продаж',
      }),
    );
  }

  updateStore(variables: MutationUpdateStoreArgs): MutationResult<'updateStore'> {
    return this.storesApi.updateStore(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении информации точки продаж',
      }),
    );
  }
}

import { gql } from 'apollo-angular';

export const queryMenuRc = gql`
  query menuRc($id: UUID!) {
    menuRc(id: $id) {
      id
      catalogId
      itemsCount {
        total
        inactive
      }
      name
      storeIds
      type
    }
  }
`;

export const queryAllInMenuPageable = gql`
  query menuRcElements($filter: MenuRcElementFilterInput, $pageable: PageRequestInput!) {
    menuRcElements(filter: $filter, pageable: $pageable) {
      elements {
        ... on ItemRc {
          active
          id
          menuId
          name
          salePrice
          sectionId
          stockUnitId
          leftMargin
          rightMargin
          catalogProductId
        }
        ... on SectionRc {
          active
          color
          depthLevel
          catalogSectionId
          id
          image {
            id
            imageSizes {
              url
            }
          }
          leftMargin
          menuId
          name
          parentId
          rightMargin
        }
      }
      page {
        pageNumber
        pageSize
        total
        totalPages
      }
    }
  }
`;

export const queryMenuElementsShort = gql`
  query menuRcElements($filter: MenuRcElementFilterInput, $pageable: PageRequestInput!) {
    menuRcElements(filter: $filter, pageable: $pageable) {
      elements {
        ... on ItemRc {
          id
          name
          stockUnitId
          leftMargin
          rightMargin
          catalogProductId
        }
        ... on SectionRc {
          color
          catalogSectionId
          id
          leftMargin
          name
          rightMargin
        }
      }
      page {
        pageNumber
        pageSize
        total
        totalPages
      }
    }
  }
`;

export const queryMenuElementShort = gql`
  query menuRcElement($elementId: UUID!) {
    menuRcElement(elementId: $elementId) {
      element {
        ... on ItemRc {
          sectionId
          rightMargin
          leftMargin
          catalogProductId
        }
        ... on SectionRc {
          id
          parentId
          catalogSectionId
          rightMargin
          leftMargin
        }
      }
    }
  }
`;

export const queryMenuElement = gql`
  query menuRcElement($elementId: UUID!) {
    menuRcElement(elementId: $elementId) {
      element {
        ... on ItemRc {
          active
          id
          menuId
          name
          salePrice
          sectionId
          stockUnitId
          leftMargin
          rightMargin
          catalogProductId
        }
        ... on SectionRc {
          active
          color
          depthLevel
          catalogSectionId
          id
          image {
            id
            imageSizes {
              url
            }
          }
          leftMargin
          menuId
          name
          parentId
          rightMargin
        }
      }
    }
  }
`;

export const queryMenuRcRootElements = gql`
  query menuRcRootElements($menuId: UUID!, $pageable: PageRequestInput!) {
    menuRcRootElements(menuId: $menuId, pageable: $pageable) {
      elements {
        ... on ItemRc {
          active
          id
          menuId
          name
          salePrice
          sectionId
          stockUnitId
          leftMargin
          rightMargin
          catalogProductId
        }
        ... on SectionRc {
          active
          color
          depthLevel
          id
          image {
            id
            imageSizes {
              url
            }
          }
          leftMargin
          menuId
          name
          parentId
          catalogSectionId
          rightMargin
        }
      }
      page {
        pageNumber
        pageSize
        total
        totalPages
      }
    }
  }
`;

export const queryMenuRcImmediateDescendants = gql`
  query menuRcImmediateDescendants($menuId: UUID!, $parentId: UUID!, $pageable: PageRequestInput!) {
    menuRcImmediateDescendants(menuId: $menuId, parentId: $parentId, pageable: $pageable) {
      elements {
        ... on ItemRc {
          active
          id
          menuId
          name
          salePrice
          sectionId
          stockUnitId
          leftMargin
          rightMargin
          catalogProductId
        }
        ... on SectionRc {
          active
          color
          depthLevel
          id
          image {
            id
            imageSizes {
              url
            }
          }
          leftMargin
          menuId
          name
          parentId
          catalogSectionId
          rightMargin
        }
      }
      page {
        pageNumber
        pageSize
        total
        totalPages
      }
    }
  }
`;

export const mutationMoveElements = gql`
  mutation moveElements($input: MoveElementsInput!) {
    moveElements(input: $input) {
      status {
        result
      }
    }
  }
`;

export const mutationCreateMenuRc = gql`
  mutation createMenuRc($menu: MenuRcCreateInput!, $struct: MenuRcStructureInput!) {
    createMenuRc(menu: $menu, struct: $struct) {
      menu {
        id
      }
    }
  }
`;

export const mutationAddMenuRc = gql`
  mutation addMenuRc($menuId: UUID!, $struct: MenuRcStructureInput!, $categoryId: UUID) {
    addMenuRc(menuId: $menuId, struct: $struct, categoryId: $categoryId) {
      status {
        result
        errorMessage
      }
    }
  }
`;

export const mutationDeleteMenuRc = gql`
  mutation deleteMenuRc($id: UUID!) {
    deleteMenuRc(id: $id) {
      status {
        result
        errorMessage
      }
    }
  }
`;

export const mutationUpdateMenuRc = gql`
  mutation updateMenuRc($input: UpdateMenuInput!) {
    updateMenuRc(input: $input) {
      menu {
        id
        storeIds
        itemsCount {
          inactive
          total
        }
        type
        name
      }
      status {
        result
      }
    }
  }
`;

export const mutationCreateMenuSectionRc = gql`
  mutation createSectionRc($input: SectionCreateInput!) {
    createSectionRc(input: $input) {
      section {
        id
      }
      status {
        result
      }
    }
  }
`;

export const mutationUpdateMenuSectionRc = gql`
  mutation updateSectionRc($input: SectionUpdateInput!) {
    updateSectionRc(input: $input) {
      status {
        result
      }
      section {
        active
        color
        id
        image {
          id
          imageSizes {
            url
          }
        }
        leftMargin
        menuId
        name
        rightMargin
        parentId
      }
    }
  }
`;

export const mutationDeleteMenuSectionRc = gql`
  mutation deleteSectionRc($id: UUID!) {
    deleteSectionRc(id: $id) {
      status {
        result
      }
    }
  }
`;

export const mutationUpdateMenuItemRc = gql`
  mutation updateItemRc($item: UpdateItemInput!) {
    updateItemRc(item: $item) {
      status {
        result
      }
      item {
        active
        id
        menuId
        name
        salePrice
        stockUnitId
      }
    }
  }
`;

export const mutationDeleteMenuItemRc = gql`
  mutation deleteItemRc($id: UUID!) {
    deleteItemRc(id: $id) {
      status {
        result
      }
    }
  }
`;

export const mutationDuplicateMenuRc = gql`
  mutation duplicateMenuRc($input: MenuRcDuplicateInput) {
    duplicateMenuRc(input: $input) {
      storesMenus {
        menuId
        storeId
      }
    }
  }
`;

export const getPrimePriceAndImageForMenuItem = gql`
  query stockUnits($filter: StockUnitFilterInput, $pageRequest: PageRequestInput) {
    stockUnits(filter: $filter, pageRequest: $pageRequest) {
      content {
        id
        primePrice {
          amountValue
          currencyUnit
        }
        weightedAveragePrimePrice {
          amountValue
          currencyUnit
        }
        images {
          id
          originalFileName
          extension
          imageSizes {
            url
          }
        }
      }
    }
  }
`;

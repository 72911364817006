<div
  class="nm-menu-content"
  [ngStyle]="{ maxHeight: maxHeightValue, minWidth: minWidthValue, maxWidth: maxWidthValue }"
  [attr.data-test-id]="testId"
>
  <ng-content select="[header]"></ng-content>
  <nm-menu-header
    *ngIf="header"
    [label]="header.label"
    [description]="header.description"
    [iconLeft]="header.iconLeft"
    [iconRight]="header.iconRight"
    [testId]="header.label"
    [imageUrl]="header.imageUrl"
    [type]="header.imageType"
    [imageTitle]="header.imageTitle"
    [multiline]="header.multiline"
    (headerClick)="header.onClick()"
    (leftIconClick)="header.onLeftIconClick()"
    (rightIconClick)="header.onRightIconClick()"
    (imageLoad)="onItemImageLoad(header)"
    (imageError)="onItemImageError(header)"
  ></nm-menu-header>

  <div *ngIf="showSearch" class="search" (click)="$event.stopPropagation()">
    <span *ngIf="!items?.length && searchText" class="empty">Результатов не найдено</span>
  </div>

  <ng-template [ngIf]="itemsLoading">
    <ng-template [ngTemplateOutlet]="spinner"></ng-template>
  </ng-template>

  <ng-template [ngIf]="!itemsLoading">
    <div
      *ngIf="!displayVitualScroll"
      #itemsContainer
      class="items-container"
      [ngStyle]="{ maxHeight: maxItemsHeightValue }"
    >
      <ng-template [ngTemplateOutlet]="menuItemsTpl" [ngTemplateOutletContext]="{ items: items }"></ng-template>
    </div>

    <virtual-scroller
      *ngIf="displayVitualScroll"
      #scroll
      class="items-container"
      [items]="items"
      [ngStyle]="{ height: maxItemsHeightValue }"
      [enableUnequalChildrenSizes]="true"
    >
      <ng-template
        [ngTemplateOutlet]="menuItemsTpl"
        [ngTemplateOutletContext]="{ items: scroll.viewPortItems }"
      ></ng-template>
    </virtual-scroller>
  </ng-template>
  <div class="content" (click)="$event.stopPropagation()">
    <ng-content></ng-content>
  </div>
  <div class="controls" *ngIf="showOkButton || showCancelButton">
    <nm-button
      *ngIf="showCancelButton"
      [variant]="cancelButtonVariant"
      [fullWidth]="true"
      testId="button-cancel"
      (click)="handleCancelClick($event)"
    >
      {{ cancelButtonLabel }}
    </nm-button>

    <nm-button
      *ngIf="showOkButton"
      [variant]="okButtonVariant"
      [disabled]="(!selected?.size && isStoreFilter) || okClickDisabled"
      [fullWidth]="true"
      testId="button-ok"
      (click)="handleOkClick($event)"
    >
      {{ okButtonLabel }}
    </nm-button>
  </div>
</div>

<ng-template #menuItemsTpl let-items="items">
  <nm-menu-item
    *ngIf="!!selectAllSettings && items && items.length > 0 && items[0].label !== 'loading'"
    class="select-all-item"
    checkboxPosition="right"
    [selected]="allSelected"
    [label]="selectAllSettings.title"
    [description]="selectAllSettings.subtitle"
    [selectOnClick]="true"
    (selectedChange)="onAllSelectedChange($event)"
  ></nm-menu-item>
  <div *ngFor="let item of items">
    <nm-menu-header
      *ngIf="item.type === 'subheader'"
      [label]="item.label"
      [iconLeft]="item.iconLeft"
      [iconRight]="item.iconRight"
      [testId]="item.label"
      (headerClick)="item.onClick()"
      (leftIconClick)="item.onLeftIconClick()"
      (rightIconClick)="item.onRightIconClick()"
    ></nm-menu-header>
    <div *ngIf="item.type === 'divider'" class="divider"></div>
    <ng-template [ngIf]="item.type === 'loading'">
      <ng-template [ngTemplateOutlet]="spinner"></ng-template>
    </ng-template>
    <nm-menu-item
      *ngIf="item.type === 'item'"
      [label]="item.label"
      [description]="showDescription || item.showItemDescription ? item.description : ''"
      [suffix]="item.suffix"
      [selected]="(selected && selected.has(item.id)) || !!allSelected"
      [disabled]="item.disabled"
      [iconLeft]="item.iconLeft"
      [iconRight]="item.iconRight"
      [iconRightColor]="item.iconRightColor"
      [imageUrl]="item.imageUrl"
      [imageColor]="item.imageColor"
      [type]="item?.imageType"
      [expandable]="item.expandable"
      [expanded]="item.expanded"
      [depthLevel]="item.depthLevel"
      [imageTitle]="item.imageTitle"
      [multiline]="item.multiline"
      [allowMultiselect]="allowMultiselect"
      [checkboxPosition]="checkboxPosition"
      [showRadioSelect]="showRadioSelect"
      [selectOnClick]="selectOnItemClick"
      [toggleActive]="item.toggleActive"
      [isToggle]="item.isToggle"
      [showActiveItem]="selected && selected.has(item.id) && showActiveItem"
      [isIndeterminate]="isIndeterminate || item.isIndeterminate"
      (checkboxChange)="onCheckboxChange(item, $event)"
      (click)="handleItemClick(item, $event)"
      (selectedChange)="onSelectedChange(item)"
      (checkboxClick)="onCheckboxClick(item, $event)"
      (toggleClick)="onToggleClick(item, $event)"
      (rightIconClick)="onRightIconClick(item)"
      (expandClick)="onExpandClick(item, $event)"
      (imageLoad)="onItemImageLoad(item)"
      (imageError)="onItemImageError(item)"
      [testId]="item.id"
    ></nm-menu-item>
  </div>
  <div *ngIf="canLoadMore" nmScrollVisibility [threshold]="0.5" (visible)="loadMoreItems.emit()" class="loading">
    <nm-progress-spinner mode="indeterminate" [diameter]="24"></nm-progress-spinner>
  </div>
</ng-template>
<ng-template #spinner>
  <div class="loading">
    <nm-progress-spinner mode="indeterminate" [diameter]="24"></nm-progress-spinner>
  </div>
</ng-template>

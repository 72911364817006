import { gql } from 'apollo-angular';

export const fragmentCategorySnapshot = gql`
  fragment CategorySnapshot on CategorySnapshot {
    id
    name
  }
`;

export const fragmentCategoryReport = gql`
  fragment CategoryReport on CategoryReport {
    categoryId
    categoryName
    categories {
      ...CategorySnapshot
    }
    pieceQuantity
    literQuantity
    kilogramQuantity
    ordersQuantity
    primePrice
    revenue
    revenuePercent
    tax
    profit
    foodCost
  }
  ${fragmentCategorySnapshot}
`;

export const fragmentCategoryReportPage = gql`
  fragment CategoryReportPage on CategoryReportPage {
    content {
      ...CategoryReport
    }
    totalPages
    total
    pageNumber
  }
  ${fragmentCategoryReport}
`;

export const fragmentCategoryReportTotal = gql`
  fragment CategoryReportTotal on CategoryReportTotal {
    pieceQuantity
    literQuantity
    kilogramQuantity
    ordersQuantity
    primePrice
    revenue
    tax
    profit
    foodCost
  }
`;

export const fragmentCategoryReportOutput = gql`
  fragment CategoryReportOutput on CategoryReportOutput {
    rows(pageRequest: $pageRequestInput, sort: $sort) {
      ...CategoryReportPage
    }
    total {
      ...CategoryReportTotal
    }
  }
  ${fragmentCategoryReportPage}
  ${fragmentCategoryReportTotal}
`;

export const queryCategoryReport = gql`
  query categoryReport($input: CategoryReportInput!, $pageRequestInput: PageRequestInput, $sort: [CategoryReportSort!]) {
    categoryReport(input: $input) {
      ...CategoryReportOutput
    }
  }
  ${fragmentCategoryReportOutput}
`;

export const queryCategoryReportTimeSeries = gql`
  query categoryReportTimeSeries(
    $categoryReportInput: CategoryReportInput!
    $timeSeriesInput: TimeSeriesInput!
    $revenue: Boolean!
    $profit: Boolean!
    $primePrice: Boolean!
  ) {
    categoryReportTimeSeries(categoryReportInput: $categoryReportInput, timeSeriesInput: $timeSeriesInput) {
      revenue @include(if: $revenue)
      profit @include(if: $profit)
      primePrice @include(if: $primePrice)
      recordedAt
    }
  }
`;

import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { BehaviorSubject, map, Observable, take } from 'rxjs';

import { ColorThemeService } from '@app/shared/service/color-theme/color-theme.service';
import { DomainService } from '@app/shared/service/domain/domain.service';
import { ImagesService } from '@app/shared/service/images.service';
import { NavigationDrawerService } from '@app/shared/service/navigation-drawer.service';
import { SVG_ICONS_TYPE } from '@constants';
import { SessionStorage } from '@services/api';
import { AuthService } from '@services/auth';
import { AccountStorage, DestroyService } from '@services/core';
import { OrganizationService } from '@services/settings';
import { RedirectService, SubscriptionAlertsService } from '@services/shared';
import { Account, BannerType, CoreSchema, NavDrawerItem, OrganizationSite } from '@typings';

import { DropdownMenuComponent } from '../dropdown-menu/dropdown-menu.component';
import { MenuItem } from '../menu-item/menu-item.model';

@Component({
  selector: 'nm-navigation-drawer',
  templateUrl: './navigation-drawer.component.html',
  styleUrls: ['./navigation-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class NavigationDrawerComponent implements AfterViewInit {
  @Input() testId: string;
  @Input() isCollapsed: boolean;
  @Input() isCompactView: boolean;
  @Input() isMobile: boolean;
  @Input() set account(value: Account | null) {
    this.#account = value;
    this.setAccountName();
    this.prepareProfileMenu();
  }
  get account(): Account | null {
    return this.#account;
  }
  @Input() items: NavDrawerItem[];
  @Input() activeItem: NavDrawerItem;
  @Input() clickItem: NavDrawerItem;

  #account: Account | null;
  accountName$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  activeOrg: OrganizationSite;
  activeOrg$: BehaviorSubject<OrganizationSite[]> = new BehaviorSubject([] as OrganizationSite[]);
  selectedOrg$: BehaviorSubject<Set<string>> = new BehaviorSubject(new Set());
  orgMenuPositions: ConnectedPosition[] = [{ originX: 'end', originY: 'top', overlayX: 'start', overlayY: 'top' }];
  organizations$: Observable<MenuItem[]> = this.accountStorage.organizations.pipe(
    map((orgs) => {
      const orgsItem: MenuItem[] = (orgs || []).map((o) => {
        return {
          id: o.id,
          label: o?.name || `ID: ${o?.shortId}`,
          description: o?.name ? `ID: ${o?.shortId}` : '',
          imageTitle: o?.name || `N O`,
          imageUrl: '',
          type: 'item',
          imageType: 'square',
        };
      });

      return orgsItem;
    }),
  );

  @Output() toggle = new EventEmitter<Event>();
  @Output() closeSidenav = new EventEmitter();
  @Output() activateItem = new EventEmitter<NavDrawerItem>();

  @Output() dropdownState = new EventEmitter<Boolean>();

  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild('profileDropdown') profileDropdown: DropdownMenuComponent;
  @ViewChild(DropdownMenuComponent) orgsMenu: DropdownMenuComponent;

  // avatarUrl: string;
  accountId = this.accountStorage.getAccountId();
  profileButtons: MenuItem[];
  profileHeader: MenuItem;
  profileMenuPositions: ConnectedPosition[] = [{ originX: 'end', originY: 'top', overlayX: 'start', overlayY: 'top' }];

  get componentClasses(): string[] {
    const classes = ['nm-navigation-drawer'];

    if (this.isCollapsed) {
      classes.push('collapsed');
    }

    if (this.isMobile) {
      classes.push('mobile');
    }

    return classes;
  }

  get toggleIconName(): SVG_ICONS_TYPE {
    return this.isCompactView ? 'burgerMenu' : 'burgerMenuCollapse';
  }

  setAccountName() {
    this.accountName$.next(this.account ? `${this.account.firstName} ${this.account.lastName}` : '');
  }

  get accountName() {
    return this.accountName$.value;
  }

  get subscriptionBannerTitle(): string {
    return this.subscriptionAlertsService.getBannerTitle();
  }

  get subscriptionBannerDescription(): string {
    return this.subscriptionAlertsService.getBannerDescription();
  }

  get showSubscriptionBanner(): boolean {
    return this.subscriptionAlertsService.showSubscriptionBanner();
  }

  get subscriptionBannerType(): BannerType {
    return this.subscriptionAlertsService.getSubscriptionAlertType();
  }

  logoUrl: BehaviorSubject<string> = new BehaviorSubject('./assets/images/logo-apos.svg');

  get logoMarkUrl(): string {
    return this.domainService?.domain?.logoMarkUrl || './assets/images/logomark-apos.svg';
  }

  constructor(
    public sessionStorage: SessionStorage,
    public accountStorage: AccountStorage,
    private imagesService: ImagesService,
    private authService: AuthService,
    public redirectService: RedirectService,
    private organizationService: OrganizationService,
    private cdr: ChangeDetectorRef,
    public subscriptionAlertsService: SubscriptionAlertsService,
    public domainService: DomainService,
    public navigationDrawerService: NavigationDrawerService,
    public colorThemeService: ColorThemeService,
  ) {
    this.accountStorage.isChangeAccount.subscribe((_) => {
      if (this.accountStorage.getAccount() && this.accountStorage.getAccount()!.imageId) {
        this.initImage(this.accountStorage.getAccount()!.imageId!);
      } else {
        this.navigationDrawerService.avatarUrl.next('');
      }
    });

    this.organizationService.reloadOrganization.subscribe((_) => {
      this.organizationService.getOrganizationSitesByAccount(this.accountId, 0, {}, []).subscribe((organizations) => {
        this.accountStorage.updateOrganizations(organizations.data.organizationSitesByAccount.content);
      });
    });

    this.accountStorage.organization.subscribe((organization) => {
      this.accountStorage.organizations.subscribe((orgs) => {
        (orgs || []).forEach((o) => {
          if (o?.id === organization?.id) {
            this.activeOrg = o;
            this.activeOrg$.next([o]);
            this.selectedOrg$.next(new Set([o.id]));
          }
        });
      });
    });

    this.colorThemeService.logoUrl.subscribe((url) => {
      this.logoUrl.next('./' + url);
    });
  }

  public initImage(id: string): void {
    const imagesSizeInput: CoreSchema.ImagesSizeInput = {
      id,
      sizes: [
        {
          sizeType: 'avatar',
          width: 100,
          height: 100,
        },
      ],
    };

    this.getImagesWithSize([imagesSizeInput]);
  }

  public getImagesWithSize(images: CoreSchema.ImagesSizeInput[]): void {
    this.imagesService
      .getImagesWithSize(images)
      .pipe(take(1))
      .subscribe((res) => {
        if (
          res?.data?.imagesWithSize?.images?.length &&
          res?.data?.imagesWithSize?.images[0] &&
          res?.data?.imagesWithSize?.images[0]?.urls?.length &&
          res?.data?.imagesWithSize?.images[0]?.urls[0]?.url
        ) {
          this.navigationDrawerService.avatarUrl.next(res?.data?.imagesWithSize?.images[0]?.urls[0]?.url || '');
        }
        this.prepareProfileMenu();
      });
  }

  ngAfterViewInit(): void {
    this.prepareProfileMenu();
  }

  setOrganization(item: MenuItem) {
    if (item.id) {
      this.sessionStorage.setOrgId(item.id);
      this.accountStorage.getOrganizationById(item.id);
      this.orgsMenu.close();
      this.redirectService.redirectToStart(item?.id);
    }
  }

  closeAll() {
    this.accordion.closeAll();
  }

  toggleMode(event: Event): void {
    this.toggle.emit(event);
  }

  setDropdownState(value: Boolean): void {
    this.dropdownState.emit(value);
  }

  setActiveItem(item: NavDrawerItem): void {
    this.activateItem.emit(item);
  }

  isCurrentItem(item: NavDrawerItem): boolean {
    return this.activeItem?.route === item.route;
  }

  isSelectedRoute(item: NavDrawerItem): boolean {
    let res: NavDrawerItem[] = [];
    if (item.routes) {
      if (item.hasSubRoute) {
        item.routes?.forEach((d) => {
          d.isSubTitle ? res.push(...d.routes!) : res.push(d);
        });
      } else {
        res = item.routes;
      }
      return !!res?.find((childItem) => this.activeItem?.route === childItem.route);
    } else {
      return item.route === this.activeItem?.route;
    }
  }

  isSelectedTotalRoute(item: NavDrawerItem): boolean {
    return (item.route === this.activeItem?.parent?.route || item.route === this.activeItem?.route) && !!item.hideRoutes;
  }

  isClickRoute(item: NavDrawerItem): boolean {
    return this.clickItem
      ? this.clickItem.parent
        ? this.clickItem.parent.isSubTitle && this.clickItem.parent.parent
          ? this.clickItem.parent.parent.route === item.route
          : this.clickItem.parent.route === item.route
        : this.clickItem.route === item.route
      : false;
  }

  toggleProfile(): void {
    if (!this.account) {
      return;
    }

    this.redirectService.redirectToProfile(this.sessionStorage.getOrgId());
  }

  prepareProfileMenu() {
    this.profileHeader = {
      id: 'account',
      label: this.accountName || 'Владелец',
      description: 'ПИН-код: ' + this.account?.pin,
      type: 'item',
      imageUrl: this.navigationDrawerService.avatarUrl.getValue() || '',
      imageType: 'round',
      imageTitle: this.accountName || 'Владелец',
    };

    this.profileButtons = [
      {
        label: 'divider',
        type: 'divider',
      },
      {
        id: 'profileSettings',
        iconLeft: 'manageAccounts',
        label: 'Настройки профиля',
        onClick: () => {
          this.profileDropdown?.close();
          this.closeSidenav.emit();
          this.toggleProfile();
        },
        type: 'item',
      },
      {
        id: 'support',
        iconLeft: 'supportAgent',
        label: 'Служба поддержки ↗',
        onClick: () => window.open('https://t.me/Absolutpos_bot', '_blank'),
        type: 'item',
      },
      {
        id: 'question',
        iconLeft: 'question',
        label: 'База знаний ↗',
        onClick: () =>
          window.open('https://www.notion.so/nomia2/57a43c8fbe574247abe981cdb8e03279?v=4f6f790d697b4d53bcc01fcf797f1bf0&pvs=4', '_blank'),
        type: 'item',
      },
      {
        id: 'exit',
        iconLeft: 'logout',
        label: 'Выход',
        onClick: () => this.logout(),
        type: 'item',
      },
      {
        label: 'divider',
        type: 'divider',
      },
    ];
    this.cdr.detectChanges();
  }

  public logout(): void {
    this.authService.logout();
    this.sessionStorage.clear();
  }

  clickOnRootRoute(item: NavDrawerItem) {
    if (item.route === 'application' || item.route === 'startingpage') {
      this.closeSidenav.emit();
    }
    if (this.isRouteAllowed(item)) {
      const orgId = this.sessionStorage.getOrgId();
      this.clickItem = item;
      if (item.onClick && orgId) {
        item.onClick(orgId, this.redirectService);
      }
    }
  }

  getNavItemIconTestId(item: NavDrawerItem): string {
    return `sidebar::item-${item.route}-icon`;
  }

  getNavItemTestId(item: NavDrawerItem): string {
    return `sidebar::item-${item.route}`;
  }

  getNavChildItemTestId(parent: NavDrawerItem, item: NavDrawerItem): string {
    return this.getNavItemTestId(parent) + '-' + item.route;
  }

  isRouteAllowed(item: NavDrawerItem): boolean {
    const roles = this.accountStorage.getRole();
    return (
      roles.includes('owner') || roles.includes(item.route || '') || roles.includes(item.route + '.read') || item.route === 'startingPage'
    );
  }

  closeSubscriptionBanner(): void {
    this.subscriptionAlertsService.hideSubscriptionBanner();
  }
}
